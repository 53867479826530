import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, Typography, Checkbox } from '@mui/material';
import parse from 'html-react-parser';

import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

const OptionItem = ({
  optionKey,
  mediaKey,
  currentAnswer,
  selectedAnswer,
  handleAnswerSelect,
  convertedOutput,
  currentQuestionData,
}) => {
  return (
    <Grid key={optionKey} item sm={12}>
      <Box display="flex" flexDirection={'row'} alignItems={'center'}>
        <span
          style={{
            fontSize: '1.25rem',
            fontWeight: '600',
            color: '#000',
            marginRight: '.5rem',
          }}
        >
          {optionKey.substring(optionKey.length - 1).toLowerCase()}.
        </span>
        <Checkbox
          checked={selectedAnswer === currentAnswer}
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          onClick={() => handleAnswerSelect(currentAnswer)}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: '700',
              color: '#000',
            }}
          >
            {currentQuestionData[optionKey] && (
              <Latex>{currentQuestionData[optionKey]}</Latex>
            )}
          </Typography>
          {currentQuestionData.type !== 'text' &&
            currentQuestionData[mediaKey] && (
              <>
                {currentQuestionData.type === 'image' ? (
                  <img
                    src={currentQuestionData[mediaKey]}
                    alt={`option-${optionKey}-media`}
                    style={{
                      width: '100px',
                      height: '100px',
                      marginTop: '10px',
                    }}
                  />
                ) : (
                  <>
                    {/* Your video rendering logic */}
                    <video controls width="200px" height="150px">
                      <source
                        src={currentQuestionData[mediaKey]}
                        type={`video/${currentQuestionData[mediaKey]
                          .split('.')
                          .pop()
                          .toLowerCase()}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </>
                )}
              </>
            )}
        </div>
      </Box>
    </Grid>
  );
};

const OptionsGrid = ({
  selectedAnswer,
  handleAnswerSelect,
  convertedOutput,
  currentQuestionData,
}) => {
  const options = useMemo(
    () =>
      [
        currentQuestionData.optionA,
        currentQuestionData.optionB,
        currentQuestionData.optionC,
        currentQuestionData.optionD,
      ].filter(
        (option) => option !== null && option !== undefined && option !== '',
      ),
    [currentQuestionData],
  );

  return (
    <Grid
      container
      sx={{
        flexDirection: 'row',
        gap: '10px',
        height: '36vh',
        overflowY: 'scroll',
      }}
      spacing={2}
    >
      {options.map((item, index) => {
        const optionKey = `option${String.fromCharCode(64 + index + 1)}`;
        const mediaKey = `${optionKey}Media`;
        const currentAnswer = optionKey.slice(-1).toLowerCase();

        return (
          <OptionItem
            key={optionKey}
            optionKey={optionKey}
            mediaKey={mediaKey}
            currentAnswer={currentAnswer}
            selectedAnswer={selectedAnswer}
            handleAnswerSelect={handleAnswerSelect}
            convertedOutput={convertedOutput}
            currentQuestionData={currentQuestionData}
          />
        );
      })}
    </Grid>
  );
};

export default OptionsGrid;

import { Box, Typography } from '@mui/material';
import NoData from 'icons/noData';

export default function Index(props) {
  const { width } = props;
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {/* <Box
        sx={{
          padding: '12px',
          borderRadius: '112px',
          backgroundColor: '#faeaf55e',
        }}
      >
        <NoData />
      </Box> */}
      <Typography
        variant="h5"
        sx={{
          mt: 4,
          fontWeight: '700',
          color: '#000',
          fontFamily: 'sans-serif',
        }}
      >
        No Videos!
      </Typography>
      <Typography
        // variant="subtitle"
        fontSize={'20px'}
        color={'#666666'}
        fontWeight={'700'}
        sx={{
          // fontWeight: '500',
          width: `${width}%`,
          mt: 3,
          // textAlign: 'center',
          fontFamily: 'Inter',
        }}
      >
        {/* We're working hard to bring you exciting videos soon. */}
        No Video Lessons for you today.
      </Typography>
    </Box>
  );
}

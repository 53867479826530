import { Box, Grid } from '@mui/material';
import VideoChallenge from './VideoChallenge/VideoChallenge';
import Quiz from '../main/elements/assignments/list/Quiz';

function ShowAllContent({ videoTopic, setVideoTopic }) {
  return (
    <Box
      container
      sx={{
        height: '100%',
        marginLeft: '0px !important',
        display: 'flex',
        alignItems: 'stretch',
      }}
      className="!ml-0 !w-full"
      bgcolor={'#fff'}
      borderRadius={'30px'}
      alignItems={'start'}
    >
      <Grid flexGrow={1} item sx={{
          p: 2,
          backgroundColor: '#FFF',
          borderRadius: '30px',
          height: '100%',
          marginLeft: '0px',
          maxWidth: '50%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
          }}
        >
          <VideoChallenge videoTopic={videoTopic} />
        </Box>
      </Grid>
      <Grid flexGrow={1} item sx={{
        p: 2,
        backgroundColor: '#FFF',
        borderRadius: '30px',
        height: '100%',
        marginLeft: '0px',
        maxWidth: '50%',
      }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
          }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Quiz videoTopic={videoTopic} setVideoTopic={setVideoTopic} />
        </Box>
      </Grid>
    </Box>
  );
}

export default ShowAllContent;

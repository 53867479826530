// ** React Imports
import { useState, Fragment } from 'react';

import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// ** Icons Imports
import LogoutVariant from 'mdi-material-ui/LogoutVariant';
import { profile } from 'asset';
import { logoutUser, setUserDetails } from 'store/Slice/authSlice';
import { useDispatch } from 'react-redux';
import { question } from 'asset';
import { message } from 'asset';
import { useAppSelector } from 'store/store';
import { IconButton } from '@mui/material';
import { HelpCircleOutline } from 'mdi-material-ui';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

function capitalizeString(inputString) {
  if (!inputString) {
    return ''; // Return an empty string if the input is empty or falsy
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

function getTypeLabel(type, grade) {
  let label = capitalizeString(type);
  if (grade.length) {
    label += ` - ${grade[0]?.grade?.name}`;
  }
  return label;
}

const UserDropdown = (props) => {
  // ** Props
  const { settings } = props;
  // ** States
  const [anchorEl, setAnchorEl] = useState(null);
  const { user: userData } = useAppSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex' }} onClick={handleDropdownOpen}>
        <Badge
          overlap="circular"
          sx={{ ml: 2, cursor: 'pointer' }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Avatar
            alt={userData?.name}
            src={userData?.profileImage}
            sx={{ width: '3rem', height: '3rem' }}
          />
        </Badge>
        <Box
          sx={{
            display: 'flex',
            ml: 3,
            cursor: 'pinter',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontWeight: 600, color: '#000' }}>
            {userData?.name || userData?.email || ''}
          </Typography>
          <Typography
            variant="body2"
            lineHeight={1}
            sx={{ fontSize: '0.8rem', color: 'text.secondary' }}
          >
            {capitalizeString(
              userData?.gradeName + ', ' + userData?.type || 'Student',
            )}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: '0.6rem', color: 'text.disabled' }}
          >
            {capitalizeString(
              userData?.school ? userData?.schoolName : 'School',
            )}
          </Typography>
        </Box>
        <IconButton
          sx={{ ml: 2, height: '3rem', width: '3rem' }}
          onClick={(e) => handleDropdownOpen(e)}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction === 'ltr' ? 'right' : 'left',
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                alt={userData?.name}
                src={userData?.profileImage}
                sx={{ width: '2.5rem', height: '2.5rem' }}
              />
            </Badge>
            <Box
              sx={{
                display: 'flex',
                ml: 3,
                alignItems: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {userData?.name || userData?.email || ''}
              </Typography>
              <Typography
                variant="body2"
                lineHeight={1}
                sx={{ fontSize: '0.8rem', color: 'text.disabled' }}
              >
                {capitalizeString(
                  userData?.gradeName + ', ' + userData?.type || 'Student',
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: '0.6rem', color: 'text.disabled' }}
              >
                {capitalizeString(
                  userData?.school ? userData?.schoolName : 'School',
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <MenuItem
          sx={{ py: 2 }}
          onClick={() => navigate('/app/student/profile')}
        >
          Profile
        </MenuItem>
        <MenuItem
          sx={{ py: 2 }}
          onClick={() => {
            // Opens the default email client with a pre-filled email
            window.location.href = "mailto:help@getg3ms.com?subject=I%20need%20help%20with%20student%20UI";
          }}
        >
          <HelpCircleOutline sx={{ mr: 2 }} />
          Help
        </MenuItem>

        <Divider sx={{ mt: 0, mb: 1 }} />
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/user/view/12')}>
          <Box sx={styles}>
            <AccountOutline sx={{ mr: 2 }} />
            Profile
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
          <Box sx={styles}>
            <EmailOutline sx={{ mr: 2 }} />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
          <Box sx={styles}>
            <MessageOutline sx={{ mr: 2 }} />
            Chat
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/account-settings')}>
          <Box sx={styles}>
            <CogOutline sx={{ mr: 2 }} />
            Settings
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
          <Box sx={styles}>
            <CurrencyUsd sx={{ mr: 2 }} />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
          <Box sx={styles}>
            <HelpCircleOutline sx={{ mr: 2 }} />
            FAQ
          </Box>
        </MenuItem>
        <Divider /> */}
        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant
            sx={{ mr: 2, fontSize: '1.375rem', color: 'text.secondary' }}
          />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;

import PlayButton from '@mui/icons-material/PlayCircleFilledRounded';
import TextsmsIcon from '@mui/icons-material/Textsms';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { follower_profile, videosample } from 'asset';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../components/main/elements/assignments/VideoPlayer/VideoPlayer.scss';
import SecondIcon from '../../../media/Frame20452Third.png';
import firstIcon from '../../../media/GroupFirst.png';
import ThirdIcon from '../../../media/GroupFourth.png';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useState } from 'react';
import { getAllVideoList } from 'store/Thunk/videoThunk';
import { useEffect } from 'react';
import VideoSideBar from './VideoSideBar';
import { setVideoLoading } from 'store/Slice/videoSlice';
import EmptyVideo from 'app/student/components/emptyVideo';
import MuxPlayer from '@mux/mux-player-react';
import { useQuery } from 'api/hooks';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  position: 'absolute',
  fontSize: 18,
  right: -3,
  fontWeight: 900,
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: theme.palette.common.white,
}));

function VideoChallenge({ videoTopic }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [videoObj, setVideoObj] = useState({});

  const dispatch = useAppDispatch();

  const query = useQuery();
  const topic = query.get('topic');

  const { videoListing, videoLoading } = useAppSelector(
    (state) => state.videoReducer,
  );
  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  const [playCount, setPlayCount] = useState(0);
  const [reactionVideo, setReactionVideo] = useState(null);
  const [reactionCount, setReactionCount] = useState(0);

  useEffect(() => {
    dispatch(setVideoLoading(true));
    dispatch(
      getAllVideoList({
        pageIndex: playCount,
        topic: videoTopic,
        userId: user?.id,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        pageSize: 10,
      }),
    ).then((res) => dispatch(setVideoLoading(false)));
  }, [playCount, videoTopic]);

  const fetchData = () => {
    dispatch(setVideoLoading(true));
    dispatch(
      getAllVideoList({
        pageIndex: playCount,
        topic: videoTopic,
        userId: user?.id,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    ).then((res) => dispatch(setVideoLoading(false)));
  };

  useEffect(() => {
    let idx = 0;
    if (!topic) {
      idx = Math.floor(Math.random() * videoListing?.length);
    }
    if (videoListing?.length > 0) {
      setVideoObj(videoListing[idx]);
      setReactionVideo(videoListing[idx]?.id);
      setReactionCount(videoListing[idx].Reaction.length);
    }
  }, [videoListing, topic]);

  function removeSeqN(text) {
    const arrs = text.split('_');
    return arrs[arrs.length - 1];
  }

  return (
    <Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {videoLoading ? (
        <Box
          height={'100%'}
          display={'flex'}
          alignItems={'start'}
          justifyContent={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          container
          justifyContent="space-evenly"
          alignItems="center"
          display={'flex'}
          height={'100%'}
          width={'100%'}
          gap={2}
        >
          {videoListing && videoListing.length > 0 ? (
            <>
              <Box
                xs={12}
                sm={8}
                md={8}
                lg={8}
                height={'100%'}
                width={'100%'}
                flex={1}
                className="!flex-1 items-center self-center justify-center !max-w-full"
              >
                <Box
                  height={'90%'}
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Box sx={{ position: 'absolute' }}>
                    <IconButton
                      onClick={() =>
                        navigate(`/app/student/video?videoId=${videoObj?.id}`, { state: videoTopic })
                      }
                    >
                      <PlayButton
                        sx={{
                          width: '60px',
                          height: '60px',
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Box>
                  <img
                    style={{ borderRadius: '12px', objectFit: 'stretch', height: '100%'}}
                    width={'100%'}
                    className="!h-full object-cover"
                    src={`https://image.mux.com/${videoObj?.playbackId}/thumbnail.png`}
                    alt="Test"
                    loading="lazy"
                  />

                  {/* <MuxPlayer
                    poster=''
                    playbackId={videoObj?.playbackId}
                    title={'title'}
                    streamType="on-demand"
                    onPlay={() => navigate(`/app/student/video?videoId=${videoObj?.id}`)}
                    style={{ aspectRatio: 10 / 9, height: '600px' }}
                  /> */}
                </Box>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    {removeSeqN(videoObj?.title || '')}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <VideoSideBar video={videoObj} fetchData={fetchData} />
              </Box>
              {/* <Box
                component={Paper}
                sx={{
                  padding: '40px 4px',
                  borderRadius: '10px !important',
                  height: '300px',
                  width: '80px',
                  borderRadius: '48px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className="videoResponseButtons"
              >
                <Box>
                  <Badge
                    overlap="circular"
                    sx={{ cursor: 'pointer' }}
                    badgeContent={<BadgeContentSpan>+</BadgeContentSpan>}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Avatar
                      alt="John Doe"
                      sx={{ width: 50, height: 50 }}
                      src={follower_profile}
                    />
                  </Badge>
                </Box>
                <div>
                  <div className="dropdown">
                    <button className="videoResponseButton" style={{ width: '100%' }}>
                      <BsFillHandThumbsUpFill size={'28px'} color="#fff" />
                    </button>
                    <div className="dropdown-content">
                      <button>
                        <img src={firstIcon} alt="Like" />
                      </button>
                      <button>
                        <img src={SecondIcon} alt="Like" />
                      </button>
                      <button>
                        <img src={ThirdIcon} alt="Like" />
                      </button>
                    </div>
                  </div>
                </div>
                <button className="videoResponseButton">
                  <TextsmsIcon size={'28px'} color="#fff" sx={{ color: 'white' }} />
                </button>
              </Box> */}
            </>
          ) : (
            <EmptyVideo />
          )}
        </Box>
      )}
    </Box>
  );
}

export default VideoChallenge;

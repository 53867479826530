import {
  Box,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useAppSelector } from 'store/store';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

export default function AssignmentView(props) {
  const { onClose, open, selectedAssignment } = props;
  const { user } = useAppSelector((state) => state.authReducer);

  let totalPercentage = 0;
  let correctScore = 0;
  let incorrectScore = 0;
  let AllQuestions = [];

  if (selectedAssignment?.type === 'Daily Practice') {
    const results = selectedAssignment?.template?.QuizResult;
    // totalPercentage = results?.length
    //   ? (results?.reduce(
    //     (total, obj) => total + parseFloat(obj.percentage1st),
    //     0,
    //   ) / results?.length)
    //   : 0;
    if (results?.length) {
      const obj = results[0];
      totalPercentage = obj?.percentage1st;
      // results?.forEach((obj) => {
      obj?.answerCorrect1st?.forEach((answer) => {
        if (answer.correct_answer === answer.student_answer) {
          correctScore++;
        } else {
          incorrectScore++;
        }
      });
      // });
      AllQuestions = results[0]?.answerCorrect1st;
    }

    // AllQuestions =
    // results?.reduce(
    //     (mergedArray, obj) => {
    //       return mergedArray.concat(obj.answerCorrect1st);
    //     },
    //     [],
    //   );
  } else {
    totalPercentage = selectedAssignment?.school_assignment_quiz_results
      ?.length
      ? (selectedAssignment?.school_assignment_quiz_results?.reduce(
        (total, obj) => total + parseFloat(obj.percentage),
        0,
      ) / selectedAssignment?.school_assignment_quiz_results?.length)
      : 0;
    if (selectedAssignment?.school_assignment_quiz_results?.length)
      selectedAssignment?.school_assignment_quiz_results?.forEach((obj) => {
        obj?.answerCorrect?.forEach((answer) => {
          if (answer.correct_answer === answer.student_answer) {
            correctScore++;
          } else {
            incorrectScore++;
          }
        });
      });
    AllQuestions =
      selectedAssignment?.school_assignment_quiz_results?.reduce(
        (mergedArray, obj) => {
          return mergedArray.concat(obj.answerCorrect);
        },
        [],
      );
  }
  console.log('mergedAnswerCorrect', AllQuestions);
  const handleListItemClick = (value) => {
    onClose(false);
  };
  return (
    <Dialog
      onClose={onClose}
      fullWidth
      open={open}
      className="assign-dialog"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1026px',
        },
      }}
    >
      <Stack className="flex justify-between" m={4}>
        <DialogTitle color={'#131515'}>Quiz</DialogTitle>
        <IconButton
          onClick={() => handleListItemClick(false)}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[600],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={4} mt={4} px={2} mb={4}>
          <Grid item md={3}>
            <Typography
              className="!font-semibold !text-base !text-black"
              onClick={() => console.log(correctScore)}
            >
              Assignment
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignment?.type || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Standard
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignment?.template?.subTopic[0]?.subTopic?.name ||
                'N/A'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Skill
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignment?.template?.skill[0]?.skill?.name || 'N/A'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Due Date
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {moment(selectedAssignment?.dueDate).format('MMMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Classroom Name
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignment?.classroom?.name || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Department
            </Typography>
            <Typography className="!font-normal !text-base !text-gray-500">
              {selectedAssignment?.template?.topic[0]?.topic?.name || ''}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Student
            </Typography>
            <Box className="flex gap-2 justify-center items-center">
              <img
                className="w-8 rounded-full h-8"
                src={user?.profileImage || ''}
                alt=""
              />
              <Typography className="!font-normal !text-base !text-gray-500">
                {user?.name || ''} (ID:{' '}
                {user?.id || ''})
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Typography className="!font-semibold !text-base !text-black">
              Teacher
            </Typography>
            <Box className="flex gap-2 justify-center items-center">
              <img
                className="w-8 rounded-full h-8"
                src={selectedAssignment?.assignBy?.profileImage || ''}
                alt=""
              />
              <Typography className="!font-normal !text-base !text-gray-500">
                {selectedAssignment?.assignBy?.name || ''} (ID:{' '}
                {selectedAssignment?.assignBy?.id || ''})
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          className="flex"
          spacing={2}
          p={2}
          mt={4}
          bgcolor={'#F9F9F9'}
          container
        >
          <Grid item md={6}>
            <Typography className="!font-semibold !text-base !text-black">
              Questions
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Score:{' '}
              <span
                style={{
                  color: Math.round(totalPercentage) > 79 ? 'green' : 'red',
                }}
              >
                {Math.round(totalPercentage)}%
              </span>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Correct: <span style={{ color: 'green' }}>{correctScore}</span>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="!font-semibold !text-base !text-black">
              Incorrect: <span style={{ color: 'red' }}>{incorrectScore}</span>
            </Typography>
          </Grid>
        </Grid>
        <>
          {AllQuestions?.map((question, key) => (
            <Grid container spacing={4} mt={4}>
              <Grid item md={2}>
                {key + 1}.  Question
              </Grid>
              <Grid item md={7}>
                <Latex>{question?.questionTitle}</Latex>
              </Grid>
              <Grid item md={3} textAlign={'left'}>
                Answer:{' '}
                <span style={{ textTransform: 'uppercase' }}>
                  {question?.correct_answer}
                </span>{' '}
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      question?.correct_answer === question?.student_answer
                        ? 'green'
                        : 'red',
                  }}
                >
                  {question?.correct_answer === question?.student_answer
                    ? 'Correct'
                    : 'Incorrect'}
                </span>
              </Grid>
            </Grid>
          ))}
        </>
      </Stack>
    </Dialog>
  );
}

import { createContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SelectAssignmentList from './components/main/elements/assignments/selectAssignmentList';

//*** Layouts
import { Box } from '@mui/material';
import { Circles } from 'react-loader-spinner';
import { auth } from '../../api/firebase';
import ShowAllConent from './components/Layout/ShowAllConent';
import { useQuery } from 'api/hooks';
import { logoutUser } from 'store/Slice/authSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setShowTop } from 'store/Slice/userSlice';
import { ArrowBack } from '@mui/icons-material';
import TopSquad from './components/TopSquad/TopSquad';
import EnterPin from './components/pincode/EnterPinCode';
import Waiting from './components/pincode/Waiting';
import Completed from './components/pincode/completed';
import Timeout from './components/pincode/timeout';
import PinCode from './components/pincode';
import ShowAllContent from './components/Layout/ShowAllContent';

export const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <SidebarContext.Provider value={[sidebarOpen, setSidebarOpen]}>
      {children}
    </SidebarContext.Provider>
  );
};

const UserProvider = ({ children, currentUser, setCurrentUser }) => {
  return (
    <SidebarContext.Provider value={[currentUser, setCurrentUser]}>
      {children}
    </SidebarContext.Provider>
  );
};

const AppModule = () => {
  const dispatch = useAppDispatch();
  const [currentUser, setCurrentUser] = useState('');
  const [videoTopic, setVideoTopic] = useState('');
  const { quizList } = useAppSelector((state) => state.quizReducer);
  const { showTop } = useAppSelector((state) => state.userReducer);

  const navigate = useNavigate();
  const query = useQuery();
  const topic = query.get('topic');
  const assignment = query.get('assignment');
  console.log('assignments2', assignment);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        localStorage.removeItem('userData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('uuid');
        localStorage.removeItem('header');
        dispatch(logoutUser());
        navigate('/');
      } else setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (topic) {
      if (videoTopic === topic) return;
      setVideoTopic(topic);
    } else {
      if (videoTopic !== '') {
        setVideoTopic('');
      }
    }
  }, [topic, videoTopic]);
  if (!currentUser)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Circles
          height="80"
          width="80"
          color="#20A58A"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  const isMobileDevice = /Mobi/.test(navigator.userAgent); // Check if 'Mobi' is present in the user agent

  if (isMobileDevice) navigate('/redirectWeb');

  return (
    <UserProvider currentUser={currentUser} setCurrentUser={setCurrentUser}>
      {assignment ? (
        <PinCode />
      ) : (
        <Box height="100%">
          {/* <SelectAssignmentList
            data={
              <Box
                sx={{
                  fontSize: '20px',
                  fontWeight: '700',
                  pl: '20px',
                  py: '5px',
                }}
              >
                New assignments to{' '}
                <span style={{ color: '#C73E9D' }}>
                  {quizList?.questions?.length > 0
                    ? 'earn $G3MS!'
                    : 'No Assignments'}
                </span>
              </Box>
            }
          /> */}
          {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
          <ShowAllContent
            videoTopic={videoTopic}
            setVideoTopic={setVideoTopic}
          />
          {/* {assignmentType === 'reading' && assignmentType !== 'all' && <ShowChallengeTask />}
          {assignmentType !== 'all' && assignmentType === 'math' && <ShowVideoTask />}
          {assignmentType === 'money' && assignmentType !== 'all' && <ShowVideo />}
          {assignmentType === 'investing' && assignmentType !== 'all' && <ShowMath />}
          {assignmentType === 'fun_content' && assignmentType !== 'all' && <ShowMath />}
          {assignmentType === 'relationships' && assignmentType !== 'all' && <ShowChallenge />}
          {assignmentType === 'Web3' && assignmentType !== 'all' && <ShowChallengeVideo />} */}
          {/* </Grid> */}
        </Box>
      )}
    </UserProvider>
  );
};

export default AppModule;

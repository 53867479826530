import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from '../profile.module.scss';

// Define a wrapper component for Typography with isTitle prop
const TypographyWrapper = styled(({ isTitle, ...other }) => (
  <Typography {...other} />
))(({ theme, isTitle, color }) => ({
  color: color || '#000',
  fontSize: '12px',
  fontWeight: isTitle ? 700 : 500,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
}));

const Card = ({
  status,
  skill,
  title,
  backgroundColor,
  startDate,
  endDate,
  score,
  progress,
  type,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6,1fr)',
          background: backgroundColor,
          height: 52,
          borderRadius: '8px',
          textAlign: 'left',
          alignItems: 'center',
          gap: '20px',
          pl: 2,
        }}
      >
        <TypographyWrapper isTitle={true}>{title}</TypographyWrapper>
        <TypographyWrapper>
          <Tooltip title={skill}>
            <span className={styles.ellipse_text}>{skill}</span>
          </Tooltip>
        </TypographyWrapper>
        <TypographyWrapper>{score || ''}</TypographyWrapper>
        <TypographyWrapper>{startDate}</TypographyWrapper>
        <TypographyWrapper>{endDate}</TypographyWrapper>
        {(progress || status) && type === 'template' ? (
          progress ? (
            <TypographyWrapper color={progress < 75 ? 'red' : '#000'}>
              {progress + '%'}
            </TypographyWrapper>
          ) : (
            <TypographyWrapper>{status && status}</TypographyWrapper>
          )
        ) : (
          <TypographyWrapper>{status && status}</TypographyWrapper>
        )}
      </Box>
    </>
  );
};

export default Card;

import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import CircularProgressWithLabel from '@core/components/CircularWithValueLabel';
import right from './right.mp3';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

import wrong from './wrong.mp3';
import { follower_profile } from 'asset';
import { useEffect, useState, useCallback, useMemo } from 'react';
import QuizWarning from 'dialog/quizWarning';
import SuccessAnswer from 'dialog/successAnswer';
import WrongAnswer from 'dialog/wrongAnswer';
import QuizScore from './QuizScroe';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getLeaderList,
  getQuizList,
  reviewQuestion,
  submitQuestion,
} from 'store/Thunk/quizThunk';
import MuxPlayer from '@mux/mux-player-react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Failed2Dialog from 'dialog/failed2Popup';
import './dailyQuiz.scss';
import { getUserData } from 'store/Thunk/userThunk';
import EmptyQuiz from 'app/student/components/emptyQuiz';
import {
  setHideTimer,
  setPauseTimer,
  setQuizLoading,
  setTimeGiven,
} from 'store/Slice/quizSlice';
import { Circles } from 'react-loader-spinner';
import reactStringReplace from 'react-string-replace';
import { useQuery } from 'api/hooks';
import OptionsGrid from '../../AnswerOption/AnswerOption';
import { convertedOutput } from 'utils/processSentence';
import AudioIcon from '../../../../../asset/person@2x.png';
import { getFontSize } from 'utils';
import { updateUserProfile } from 'store/Thunk/authThunk';
import Explantion from './Explanation';
import ReviewSuccess from 'dialog/reviewSuccess';
import { resultVideo } from 'utils/constants';
import ReviewDone from './ReviewDone';

function DailyQuiz({ state }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { leaderBoardList, quizLoading, quizList } = useAppSelector(
    (state) => state.quizReducer,
  );
  const { questionDuration } = useAppSelector((state) => state.quizReducer);

  const [template, setTemplate] = useState(null);

  const playerRef = useRef(null);
  const audioRef = useRef(null);
  const playSound = () => {
    audioRef?.current?.play();
  };
  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  const query = useQuery();
  const topic = query.get('topic');
  const skill = query.get('skill');

  const passMark = useMemo(() => {
    return 80;
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionData, setCurrentQuestionData] = useState({});
  const [quizStat, setQuizStat] = useState({
    currentQuestion: {},
    currentQuestionIndex: 0,
    totalQuestionIndex: 0,
    correctAnswered: 0,
    incorrectAnswered: 0,
    totalQuestions: 0,
    quizCompleted: false,
    quizSummary: null,
  });
  console.log('quizStat', quizStat);
  useEffect(() => {
    setQuizStat((prevQuizStat) => ({
      ...prevQuizStat, // Spread the previous state to keep other data
      currentQuestion: currentQuestionData,
      currentQuestionIndex: template?.currentQuestion,
      totalQuestions: template?.totalQuestions,
    }));
  }, [currentQuestionData, template]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [studentReply, setStudentReply] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showNext, setShowNext] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds timer
  const [showError, setShowError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showReviewSuccess, setShowReviewSuccess] = useState(true);
  const [showWrong, setShowWrong] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [totalGems, setTotalGems] = useState(0);
  const [finalMarks, setFinalMarks] = useState({ marks: 0, complete: false });
  const [answeredCount, setAnsweredCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [quizObj, setQuizObj] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [failedPopup, setFailedPopup] = useState(false);
  const [failed2Popup, setFailed2Popup] = useState(false);
  const [secondTrial, setSecondTrial] = useState(false);
  const [videoTopic, setVideoTopic] = useState('');
  const [quizSkill, setquizSkill] = useState(null);
  const [perQuestion, setPerQuestion] = useState(0);
  const [suffledVideo, setSuffledVideo] = useState({
    do_not_stop: '',
    do_it_again: '',
  });
  console.log('RAN THIS resetPage<============', quizList);
  console.log('RAN THIS resetPage<============', template);
  console.log('RAN THIS resetPage<============2', currentQuestionData);
  console.log('RAN THIS resetPage<============', quizObj);
  console.log('RAN THIS resetPage<============', quizStat?.quizCompleted);
  const resetPage = () => {
    //except video and suffledVideo
    setSecondTrial(true);
    setCurrentQuestionIndex(0);
    setCurrentQuestionData({});
    setLeaderBoard([]);
    setStudentReply([]);
    setSelectedAnswer('');
    setShowNext(false);
    setTimer(60);
    setShowError(false);
    setShowPopup(false);
    setShowSuccess(false);
    setShowWrong(false);
    setTotalMarks(0);
    setAnsweredCount(0);
    setFinalMarks({ marks: 0, complete: false });
    setTotalTime(0);
    setQuizObj({});
    setSuccessPopup(false);
    setFailedPopup(false);
    setFailed2Popup(false);
    dispatch(setQuizLoading(true));
    dispatch(
      getQuizList({
        topic: videoTopic,
        userId: user?.id,
        skill: quizSkill,
        isReview: isReview,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(setQuizLoading(false));
        dispatch(getUserData({ studentId: user?.id }));
        if (quiz) {
          navigate('/app/student/daily-quiz', { state: videoTopic });
        }
      });
  };
  useEffect(() => {
    if (topic) {
      setVideoTopic(topic);
    } else {
      setVideoTopic('');
    }
  }, [topic]);
  useEffect(() => {
    if (skill) {
      setquizSkill(skill);
    }
  }, [skill]);
  console.log('userData', userData);
  useEffect(() => {
    if (userData?.id) {
      if (quizList.length > 0) {
        console.log(
          'QUIZ LIST EXISTS',
          quizList.length,
          quizList.map((item) => item.id),
        );
        return;
      }

      console.log('::RAN THIS AGAIN<============', isReview);
      dispatch(setQuizLoading(true));
      dispatch(
        getQuizList({
          topic: videoTopic,
          skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          gradeId:
            userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      ).then((res) => {
        if (res.type === 'getQuizList/fulfilled') {
          dispatch(setQuizLoading(false));
        }
      });
    }
    // }, [videoTopic, userData?.grade, quizSkill, quizList, userData?.id]);
  }, [userData?.id]);

  const quiz = query.get('quiz');

  useEffect(() => {
    if (quizList) {
      setTemplate(quizList);
    }
  }, [quizList]);

  useEffect(() => {
    if (leaderBoardList.length) {
      setLeaderBoard(leaderBoardList);
    }
  }, [leaderBoardList]);

  const replaceRaceNumber = (text) => {
    const regex = /\^(\d+)/;
    const convertedText = reactStringReplace(text, regex, (match, i) => (
      <sup key={i}>{match}</sup>
    ));

    return convertedText;
  };

  useEffect(() => {
    if (template) {
      console.log('=========We have a template=======', template);
      setQuizObj(template);
      if (template?.id) {
        dispatch(getLeaderList({ userId: user?.id, topic: videoTopic }));
      }
      if (template?.quizQuestion?.question) {
        setCurrentQuestionData(template?.quizQuestion?.question || {});
      }
    }
  }, [template]);

  const secondAttempt = template?.order === 2;

  // Set actual template object
  let templateObj = null;
  template?.templates?.forEach((temp) => {
    if (temp.template.id === template?.questions[0]?.templateId) {
      templateObj = temp.template;
    }
  });

  useEffect(() => {
    setSuffledVideo({
      do_not_stop: resultVideo?.do_not_stop?.sort(() => Math.random() - 0.5)[0],
      do_it_again: resultVideo?.do_it_again?.sort(() => Math.random() - 0.5)[0],
      be_right_back: resultVideo?.be_right_back?.sort(
        () => Math.random() - 0.5,
      )[0],
    });
  }, []);

  useEffect(() => {
    if (template?.quizQuestion?.question) {
      setCurrentQuestionData(template?.quizQuestion?.question || {});
    }
  }, [selectedAnswer]);

  const handleAnswerSelect = (optionKey) => {
    setSelectedAnswer((prevAnswer) =>
      prevAnswer === optionKey ? '' : optionKey,
    );
    setShowNext(false);
    setShowError(false);
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 1000);
      return;
    }
  }, [showError]);

  const getG3MSPercentage = () => {
    let percentage = 0;
    if (user && Object.keys(user).length) {
      const g3msScore = totalGems + (user?.gemsTokens || 0);
      percentage = (g3msScore * 100) / user?.totalGems;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const [isSpeaking, setIsSpeaking] = useState(false);

  const getPercentage = useCallback(() => {
    let correctAnswers = 0;
    studentReply.forEach((reply) => {
      if (reply.student_answer === reply.correct_answer) {
        correctAnswers += 1;
      }
    });
    return (correctAnswers * 100) / quizObj?.questions?.length;
  }, [studentReply, quizObj]);
  const quizResult = {
    IsReviewQuestionsAvailablePerQuiz: false,
    TotalNumberOfCorrectlyAnsweredPerQuiz: 3,
    TotalNumberOfIncorrectlyAnsweredPerQuiz: 2,
    TotalQuizDurationTaken: 266,
    TotalScoreForTheQuiz: 60,
    TotalTokensEarnedPerQuiz: 17,
  };
  const percentage = getPercentage();
  const handleNextQuestion = useCallback(() => {
    dispatch(setPauseTimer(true));
    if (!template) {
      return;
    }
    const assignmentId = template?.assignmentId;

    setIsSpeaking(false);
    if (!selectedAnswer) {
      setShowError(true);
      return;
    }
    let replyObj = {
      questionId: currentQuestionData.id,
      student_answer: selectedAnswer.toLocaleLowerCase(),
      correct_answer: currentQuestionData.answer.toLocaleLowerCase(),
    };
    let replyArr = [...studentReply];
    replyArr.push(replyObj);
    setStudentReply(replyArr);
    setShowNext(true);

    dispatch(
      submitQuestion({
        assignmentId: assignmentId,
        quizMode: 'WEB',
        assignmentType: 'DailyPractice',
        question: {
          questionId: currentQuestionData.id,
          templateId: template?.templateId,
          studentAnswer: selectedAnswer.toLowerCase(),
          isReviewed: isReview,
          durationTaken: questionDuration,
        },
        studentId: user?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        console.log('response', res);
        setTotalGems((prevGems) => prevGems + res?.data?.newGemTokens || 0);
        setShowNext(true);
        if (template?.totalQuestions === template?.currentQuestion) {
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            quizCompleted: true,
          }));
        }
        // !res.data?.isQuizFinished &&

        // if (!res.data?.isQuizFinished || isReview) {
        //   dispatch(
        //     getQuizList({
        //       topic: videoTopic,
        //       skill: quizSkill,
        //       isReview: isReview,
        //       userId: userData?.id,
        //       gradeId:
        //         userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        //     }),
        //   );
        // }
        if (res.data?.isQuizFinished) {
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            quizSummary: res.data?.quizResultSummary,
          }));
          dispatch(setHideTimer(true));
        }
        if (res.data?.isAnswerCorrect) {
          setPerQuestion(() => 10);
          setTotalMarks((prevTotalMarks) => prevTotalMarks + 10);
          setAnsweredCount((prevTotalMarks) => prevTotalMarks + 1);
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            correctAnswered: prevQuizStat?.correctAnswered + 1,
          }));
        }
        if (!res.data?.isAnswerCorrect) {
          setPerQuestion(() => 0);
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            incorrectAnswered: prevQuizStat?.incorrectAnswered + 1,
          }));
        }
        selectedAnswer &&
          setShowSuccess(
            selectedAnswer.toLocaleLowerCase() ===
              currentQuestionData.answer.toLocaleLowerCase(),
          );
        selectedAnswer &&
          setShowWrong(
            selectedAnswer.toLocaleLowerCase() !==
              currentQuestionData.answer.toLocaleLowerCase(),
          );
      });
  }, [
    selectedAnswer,
    template,
    user?.id,
    currentQuestionData,
    studentReply,
    dispatch,
  ]);

  // const handleReviewQuestion = useCallback(() => {
  //   if (!template) {
  //     return;
  //   }
  //   const assignmentId = template?.id;

  //   const isAnswerCorrect =
  //     selectedAnswer.toLowerCase() === currentQuestionData.answer.toLowerCase();

  //   if (isAnswerCorrect) {
  //     setPerQuestion(() => 10);
  //     setTotalMarks((prevTotalMarks) => prevTotalMarks + 10);
  //     setAnsweredCount((prevTotalMarks) => prevTotalMarks + 1);
  //   }
  //   if (!isAnswerCorrect) {
  //     setPerQuestion(() => 0);
  //   }
  //   dispatch(
  //     reviewQuestion({
  //       templateId: currentQuestionData?.templateId,
  //       studentId: user?.id,
  //       assignmentId: assignmentId,
  //       questionId: currentQuestionData.id,
  //     }),
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       setTotalGems((prevGems) => prevGems + res?.data?.newGemTokens || 0);
  //       setShowReviewSuccess(true);
  //       setShowNext(true);
  //     });
  // }, [selectedAnswer, template, user?.id, currentQuestionData, dispatch]);

  useEffect(() => {
    (showSuccess || showWrong) && playSound();
  }, [showSuccess, showWrong]);

  useEffect(() => {
    let interval;
    const fiveMinutes = 0;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      if (!selectedAnswer && !finalMarks?.complete) {
        setShowError(true);

        const popupTimeout = setTimeout(() => {
          setShowPopup(true);
        }, fiveMinutes * 1000);

        return () => clearTimeout(popupTimeout);
      }
    }

    return () => clearInterval(interval);
  }, [timer, selectedAnswer, currentQuestionData.correctAnswer]);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === 'yes') {
      setCurrentQuestionIndex((prevQuestion) => prevQuestion + 1);
      setSelectedAnswer('');
      setTimer(60);
    } else {
      console.log('User and session flagged!');
    }
  };
  console.log('showReviewSuccess', showReviewSuccess);
  console.log('showReviewSuccess', secondTrial);
  const handleSuccessReviewDialog = () => {
    setShowReviewSuccess(false);
  };
  const handleSuccessDialog = () => {
    setShowSuccess(true);
  };

  const onCloseReviewSuccess = () => {
    setShowReviewSuccess(false);
  };
  const onCloseSuccess = () => {
    dispatch(setPauseTimer(false));
    dispatch(setTimeGiven(60));
    if (template.currentQuestion < 5 || isReview) {
      dispatch(
        getQuizList({
          topic: videoTopic,
          skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          gradeId:
            userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      );
    }
    setPerQuestion(() => 0);
    setShowSuccess(false);
    setSelectedAnswer('');
    setTimer(60);
  };
  const handleWrongDialog = () => {
    setShowWrong(true);
  };

  const onCloseWrong = () => {
    dispatch(setPauseTimer(false));
    dispatch(setTimeGiven(60));
    setShowWrong(false);
    setSelectedAnswer('');
    setTimer(60);
    if (template.currentQuestion < 5 || isReview) {
      dispatch(
        getQuizList({
          topic: videoTopic,
          skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          gradeId:
            userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      );
    }
  };
  const handleOnEnded = () => {
    if (percentage >= passMark) {
      // navigate('/app/student')
      setSuccessPopup(true);
    }
    if (percentage < passMark && !secondAttempt) {
      setFailedPopup(true);
    }
    if (percentage < passMark && secondAttempt) {
      setFailed2Popup(true);
    }
  };
  const [isReview, setIsReview] = useState(false);
  const handleReview = () => {
    setIsReview(true);
    dispatch(
      getQuizList({
        topic: videoTopic,
        skill: quizSkill,
        isReview: true,
        userId: userData?.id,
        gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    );
  };

  const handleSuccessPopup = () => {
    resetPage();
    navigate('/app/student');
  };
  const handleOnCloseFailed = () => {
    navigate('/app/student');
  };

  const handleVideo = () => {
    if (percentage >= passMark || isReview || quizStat.correctAnswered === 5) {
      return suffledVideo?.do_not_stop;
    }
    if (
      (percentage < passMark &&
        template &&
        Object.keys(template).length &&
        !secondAttempt) ||
      quizStat.correctAnswered < 5
    ) {
      return suffledVideo?.do_it_again;
    }
    if (
      percentage < passMark &&
      template &&
      Object.keys(template).length &&
      secondAttempt
    ) {
      return suffledVideo?.be_right_back;
    }
  };

  const handleSpeakClick = () => {
    setIsSpeaking(true);
  };
  const [speechSynthesisObj, setSpeechSynthesisObj] = useState(null);

  useEffect(() => {
    // const message = new SpeechSynthesisUtterance(
    //   convertedOutput(
    //     `${currentQuestionData?.question}
    //      A,
    //      ${currentQuestionData?.optionA}" ", B ,
    //      ${currentQuestionData?.optionB}" ", C ,
    //      ${currentQuestionData?.optionC}" ", D , ${currentQuestionData?.optionD}`,
    //   ),
    // );
    const question = currentQuestionData?.question;
    const optionA = currentQuestionData?.optionA;
    const optionB = currentQuestionData?.optionB;
    const optionC = currentQuestionData?.optionC;
    const optionD = currentQuestionData?.optionD;

    const options = [
      { label: 'A', value: optionA },
      { label: 'B', value: optionB },
      { label: 'C', value: optionC },
      { label: 'D', value: optionD },
    ];

    const nonEmptyOptions = options.filter(
      (option) =>
        option.value !== null &&
        option.value !== undefined &&
        option.value !== '',
    );

    const messageContent = nonEmptyOptions
      .map((option) => {
        return `, ${option.label}, ${option.value}`;
      })
      .join('');

    const message = new SpeechSynthesisUtterance(
      `${question}${messageContent}`.replaceAll('\\', ''),
    );

    // Set isSpeaking to false when done speaking
    message.onend = () => {
      setIsSpeaking(false);
    };

    const synthesis = window.speechSynthesis;

    setSpeechSynthesisObj(message);

    if (isSpeaking) {
      synthesis.speak(message);
    }
    return () => {
      synthesis.cancel(); // Make sure to cancel the speech when the component unmounts
    };
  }, [isSpeaking, currentQuestionData]);

  // const handleStopSpeech = () => {
  //   if (speechSynthesisObj) {
  //     window.speechSynthesis.cancel();
  //   }
  // };

  function removeSequenceNumber(text) {
    const arrs = text.split(':');
    return arrs[arrs.length - 1];
  }
  console.log(
    'final',
    (quizStat?.quizCompleted && !isReview) || (isReview && !template.message),
  );
  console.log('final', quizStat?.quizCompleted, isReview, template?.message);
  return (
    <Box height={'100%'} margin={0}>
      <Box m={0} height={'100%'}>
        {/* Display the total time taken */}
        {/* <Typography variant="h6">Total Time: {totalTime} seconds</Typography> */}
        {(quizLoading || !userData?.id) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Circles
              height="80"
              width="80"
              color="#20A58A"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <Card sx={{ padding: 10, height: '100%' }}>
          {/* {quizObj && Object.keys(quizObj).length > 0 ? ( */}
          {(template && !template?.message) || quizStat?.quizSummary ? (
            <>
              <div
                style={{ display: 'flex', gap: '20px', height: '100%' }}
                container
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    height: '100%',
                    flex: 2,
                    border: '6px solid rgba(76, 191, 167, 1)',
                    borderRadius: '20px',
                    background: '#F9F9F9',
                  }}
                  container
                >
                  <div
                    style={{ display: 'flex', flex: 3 }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Card
                      sx={{
                        width: '90%',
                        flex: 1,
                        display: 'flex',
                        boxShadow: 'none',
                        background: 'transparent',
                      }}
                    >
                      {(!quizStat?.quizCompleted && !template?.message) ||
                      (isReview && !template.message) ? (
                        <CardContent
                          sx={{
                            display: 'flex',
                            flex: '1',
                            flexDirection: 'column',
                          }}
                        >
                          <Box sx={{ m: 2, marginBottom: '2rem' }}>
                            {/* {templateObj?.title ? ( */}
                            {currentQuestionData?.question ? (
                              <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'left'}
                                alignItems={'center'}
                                gap={3}
                              >
                                <Box>
                                  <IconButton
                                    aria-label="Read"
                                    onClick={handleSpeakClick}
                                    size="large"
                                  >
                                    <img
                                      src={AudioIcon}
                                      alt="speaker"
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    fontSize: '32px',
                                    fontWeight: 700,
                                    color: '#131515',
                                    marginBottom: '0px',
                                  }}
                                  gutterBottom
                                >
                                  {removeSequenceNumber(
                                    template?.templateTitle || '',
                                  )}
                                </Typography>
                              </Box>
                            ) : (
                              <Skeleton
                                variant="rounded"
                                width={220}
                                height={30}
                                sx={{ mb: 3 }}
                              />
                            )}
                            {showWrong && <audio ref={audioRef} src={wrong} />}
                            {showSuccess && (
                              <audio ref={audioRef} src={right} />
                            )}
                          </Box>
                          {showError && (
                            <Alert
                              severity="error"
                              onClose={() => setShowError(false)}
                            >
                              Select Answer!
                            </Alert>
                          )}
                          {Object.entries(currentQuestionData)?.length ? (
                            <div
                              style={{
                                // m: 2,
                                padding: '0 2rem',
                                background: template?.templateColorCode
                                  ? `${
                                      template?.templateColorCode.startsWith(
                                        '#',
                                      )
                                        ? ''
                                        : '#'
                                    }${template?.templateColorCode}`
                                  : '#FFFFFF',
                                borderRadius: '20px',
                                display: 'flex',
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '0px',
                                  padding: '0px',
                                  height: '100%',
                                  width: '100%',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: `${getFontSize(
                                      currentQuestionData?.question,
                                    )}px`,
                                    fontWeight: 600,
                                    textAlign: 'center',
                                    p: 1,
                                  }}
                                  variant="subtitle2"
                                >
                                  <Latex>{currentQuestionData?.question}</Latex>
                                </Typography>
                                {currentQuestionData?.questionMedia && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',

                                      height: 'auto',
                                      width: '100%',
                                    }}
                                  >
                                    {currentQuestionData?.type === 'image' ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          height: 'auto',
                                          width: '100%',
                                        }}
                                      >
                                        <img
                                          src={
                                            currentQuestionData?.questionMedia
                                          }
                                          alt={`question-media`}
                                          style={{
                                            maxWidth: '100%',
                                            maxHeight: '65vh',
                                            width: '100%',
                                            // margin: '10px',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <video
                                          controls
                                          width="500px"
                                          height="400px"
                                          // style={{ aspectRatio: 16 / 9, height: '200px' }}
                                        >
                                          <source
                                            src={
                                              currentQuestionData?.questionMedia
                                            }
                                            type={`video/${currentQuestionData?.questionMedia
                                              .split('.')
                                              .pop()
                                              .toLowerCase()}`}
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <Skeleton
                              variant="rounded"
                              width={380}
                              height={130}
                            />
                          )}
                        </CardContent>
                      ) : (
                        // <CardContent>
                        <>
                          {/* <Grid item xs={6} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}> */}
                          <Box
                            sx={{
                              height: '100%',
                              borderRadius: '13px',
                              backgroundColor: '#F8F8F8',
                              display: 'flex',
                              // padding: '1rem',
                              width: '100%',
                            }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {percentage < passMark &&
                            secondAttempt &&
                            false &&
                            quizObj &&
                            Object.keys(quizObj).length ? (
                              <Failed2Dialog
                                open={true}
                                title={removeSequenceNumber(
                                  templateObj?.title || '',
                                )}
                                onClose={handleOnCloseFailed}
                              />
                            ) : isReview ? (
                              <ReviewDone quizStat={quizStat?.quizSummary} />
                            ) : (
                              quizObj &&
                              Object.keys(quizObj).length && (
                                <QuizScore
                                  title={removeSequenceNumber(
                                    template?.templateTitle || '',
                                  )}
                                  quizStat={quizStat?.quizSummary}
                                  name={templateObj?.name}
                                  showSecondFail={secondAttempt}
                                  showScore={secondAttempt}
                                  handleYesBtn={handleReview}
                                  answeredCount={answeredCount}
                                  totalMarks={quizObj?.questions?.length}
                                  totalGems={totalGems}
                                  resetPage={resetPage}
                                  remainingQuiz={
                                    template?.remainingTemplate || 0
                                  }
                                  handleSuccessPopup={handleSuccessPopup}
                                  successPopup={successPopup}
                                  failedPopup={failedPopup}
                                />
                              )
                            )}
                          </Box>
                        </>
                      )}
                    </Card>
                  </div>
                  {((!template?.message && !quizStat.quizCompleted) ||
                    (isReview && !template.message)) && (
                    <div
                      style={{
                        display: 'flex',
                        flex: 2,
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                          padding: '1.5rem 2rem',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '18px',
                            color: '#666666',
                            textAlign: 'right',
                            marginBottom: '2rem',
                          }}
                        >
                          {isReview
                            ? ''
                            : `Question ${template?.currentQuestion} out of
                            ${template?.totalQuestions}`}
                        </Typography>

                        <Card
                          sx={{
                            width: '100%',
                            // height: 'fit-content',
                            border: '3px solid rgba(76, 191, 167, 1)',
                            display: 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 4,
                            mt: 3,
                            background: '#F8F8F8',
                            boxShadow: '0px 4px 10px 0px #00000026',
                          }}
                        >
                          <Grid container>
                            <Grid item md={6}>
                              <Grid
                                container
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: '18px',
                                      color: '#000',
                                    }}
                                  >
                                    Per Question
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      color: '#C73E9D',
                                    }}
                                  >
                                    {perQuestion} $G3MS
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6}>
                              <Grid
                                container
                                sx={{
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: '18px',
                                      color: '#000',
                                    }}
                                  >
                                    Total Earning
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: 800,
                                      fontSize: '20px',
                                      color: '#C73E9D',
                                    }}
                                  >
                                    {user?.gemsTokens} $G3MS
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>

                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '26px',
                            color: '#131515',
                            textAlign: 'left',
                          }}
                        >
                          {currentQuestionData?.message}
                        </Typography>

                        {/* <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '22px',
                            color: '#000',
                            my: 5,
                          }}
                        >
                          Leaderboard
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            color: '#20A58A',
                            my: 5,
                          }}
                        >
                          View All{' '}
                        </Typography>
                      </Box> */}

                        <OptionsGrid
                          selectedAnswer={selectedAnswer}
                          handleAnswerSelect={handleAnswerSelect}
                          currentQuestionData={currentQuestionData}
                          convertedOutput={convertedOutput}
                        />

                        <Button
                          sx={{
                            textTransform: 'none',
                            background: '#C73E9D',
                            color: '#FFFFFF',
                            '&.MuiButtonBase-root:hover': {
                              backgroundColor: '#C73E9D',
                            },
                          }}
                          fullWidth
                          onClick={handleNextQuestion}
                        >
                          Next
                        </Button>
                        {/* <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <Box
                                  sx={{
                                    m: 2,
                                    p: 1,
                                    display: 'flex',
                                    direction: 'coulmn',
                                    justifyContent: 'space-evenly',
                                  }}
                                >
                                  <Box>
                                    <CircularProgressWithLabel
                                      timer
                                      size="5rem"
                                      progressColor="#4CBFA7"
                                      remainProgressColor="#E4F2EE"
                                      value={timer}
                                    />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        textAlign: 'center',
                                      }}
                                      variant="subtitle1"
                                    >
                                      Time
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <CircularProgressWithLabel
                                      size="5rem"
                                      progressColor="#C73E9D"
                                      remainProgressColor="#FAEAF5"
                                      value={getG3MSPercentage()}
                                      label={user?.gemsTokens}
                                    />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        textAlign: 'center',
                                      }}
                                      variant="subtitle1"
                                    >
                                      $G3MS
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid> */}

                        <Box>
                          {leaderBoard.map((obj, index) => (
                            <Card
                              key={index}
                              sx={{
                                width: '100%',
                                // height: 'fit-content',
                                border: '3px solid rgba(76, 191, 167, 1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 4,
                                mt: 3,
                                background: '#F8F8F8',
                                boxShadow: '0px 4px 10px 0px #00000026',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: '18px',
                                    color: '#333333',
                                    mr: 3,
                                  }}
                                >
                                  {index + 1}.
                                </Typography>
                                <Avatar
                                  alt="John Doe"
                                  sx={{ width: 50, height: 50 }}
                                  src={
                                    obj?.student?.profileImage
                                      ? obj?.student?.profileImage
                                      : follower_profile
                                  }
                                />
                                <Box>
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      fontSize: '18px',
                                      color: '#333333',
                                    }}
                                  >
                                    @{obj?.student?.name || ''}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: '14px',
                                      color: '#666666',
                                    }}
                                  >
                                    {obj?.student?.grade?.length
                                      ? `${obj?.student?.grade[0]?.grade?.name} grade`
                                      : ''}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: '20px',
                                    color: '#C73E9D',
                                  }}
                                >
                                  {obj?.score} $G3MS
                                </Typography>
                              </Box>
                            </Card>
                          ))}
                        </Box>
                      </Box>
                    </div>
                  )}
                </div>
                {((quizStat?.quizCompleted && !isReview) ||
                  (isReview && template.message)) && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      border: '10px solid #C73E9D',
                      borderRadius: '13px',
                      flex: 1,
                      '&video': {
                        objectFit: 'cover !important',
                      },
                    }}
                  >
                    <MuxPlayer
                      playbackId={handleVideo()}
                      className="dancing_video_player"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'red',
                        objectFit: 'cover !important',
                      }}
                      autoplay={true}
                      onEnded={handleOnEnded}
                    />
                  </Box>
                )}
              </div>

              {/* <Explantion
                template={template}
                templateObj={templateObj}
                removeSequenceNumber={removeSequenceNumber}
                currentQuestionData={currentQuestionData}
                onSubmit={handleReviewQuestion}
              /> */}

              {finalMarks?.complete && (
                <Box
                  sx={{
                    border: '2px solid #20A58A',
                    backgroundColor: '#F8F8F8',
                    padding: '10px',
                    borderRadius: '12px',
                    mt: 6,
                  }}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <Grid
                        contaniner
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '24px',
                          color: '#000',
                        }}
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontWeight: 800,
                              fontSize: '1rem',
                              color: '#000',
                            }}
                          >
                            You Now Have A Total Of
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontWeight: 800,
                              fontSize: '20px',
                              color: '#000',
                            }}
                          >
                            <span
                              style={{
                                color: '#C73E9D',
                                fontSize: '28px',
                                fontWeight: '700',
                              }}
                            >
                              {user?.gemsTokens}
                              $G3MS
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid contaniner>
                        {/* <Grid
                            item
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '24px',
                              color: '#000',
                            }}
                          >
                            {percentage >= 75 ? (
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  color: '#000',
                                }}
                              >
                                You are now{' '}
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                  }}
                                >
                                  #3
                                </span>{' '}
                                on the
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                  }}
                                >
                                  Top 10 Score Squad !
                                </span>
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  color: '#000',
                                }}
                              >
                                Keep going you are almost in the
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                    marginLeft: '1ch',
                                  }}
                                >
                                  Top 10 Score Squad !
                                </span>
                              </Typography>
                            )}
                          </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        contaniner
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            sx={{
                              textTransform: 'none',
                              width: '100%',
                              borderRadius: '8px',
                              fontWeight: 700,
                              fontSize: '20px',
                              background: '#C73E9D',
                              color: '#FFFFFF',
                              lineHeight: 1.715,
                              boxShadow: 3,
                              mt: 5,
                              '&.MuiButtonBase-root:hover': {
                                backgroundColor: '#C73E9D',
                              },
                            }}
                            fullWidth
                            onClick={() => {
                              navigate('/app/student/leaderboard/?topic=');
                            }}
                          >
                            Top 10 Score Squad
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ) : (
            <EmptyQuiz width={30} />
            // <Quiz />
          )}
        </Card>
        {showPopup && Object.keys(template)?.length && (
          <QuizWarning
            open={showPopup}
            handlePopupResponse={handlePopupResponse}
            onClose={handlePopupClose}
            videoTopic={videoTopic}
          />
        )}
        {showSuccess && (
          <SuccessAnswer
            isReview={isReview}
            open={showSuccess}
            handleSuccessDialog={handleSuccessDialog}
            onClose={onCloseSuccess}
            videoTopic={videoTopic}
          />
        )}
        {showReviewSuccess && secondTrial && (
          <ReviewSuccess
            open={showReviewSuccess}
            handleSuccessReviewDialog={handleSuccessReviewDialog}
            onClose={onCloseReviewSuccess}
            videoTopic={videoTopic}
          />
        )}
        {showWrong && (
          <WrongAnswer
            isReview={isReview}
            replaceRaceNumber={replaceRaceNumber}
            correctAnswer={currentQuestionData}
            open={showWrong}
            handle={handleWrongDialog}
            onClose={onCloseWrong}
            videoTopic={videoTopic}
          />
        )}
        {failed2Popup && (
          <Failed2Dialog
            failed2Popup={failed2Popup}
            title={removeSequenceNumber(templateObj?.title || '')}
            onClose={handleOnCloseFailed}
          />
        )}
      </Box>
    </Box>
  );
}

export default DailyQuiz;

import React, { useEffect, useState } from 'react';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setQuestionDuration } from 'store/Slice/quizSlice';
function Timer() {
  const dispatch = useAppDispatch();
  const { startQuiz } = useAppSelector((state) => state.quizReducer);
  const { timeGiven } = useAppSelector((state) => state.quizReducer);
  const { pauseTimer } = useAppSelector((state) => state.quizReducer);

  const [seconds, setSeconds] = useState(1 * timeGiven);
  useEffect(() => {
    setSeconds(1 * timeGiven);
  }, [timeGiven, pauseTimer]);
  useEffect(() => {
    let interval = null;
    if (seconds > 0 && !pauseTimer) {
      interval = setInterval(() => {
        startQuiz && setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds, startQuiz, pauseTimer]);
  useEffect(() => {
    if (seconds === 0) {
      setSeconds(1 * timeGiven);
    }
    dispatch(setQuestionDuration(seconds));
  }, [seconds, timeGiven]);
  console.log('first', seconds);
  const minutesDisplay = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secondsDisplay = (seconds % 60).toString().padStart(2, '0');
  return (
    <Box className="flex gap-1 items-center mt-2 w-full justify-center">
      <div>
        <AccessAlarmsIcon sx={{ fontSize: '25px' }} />
      </div>
      <h3
        style={{
          fontSize: '25px',
          width: '60px',
          color: seconds < 11 ? 'red' : 'inherit',
        }}
      >
        {minutesDisplay}:{secondsDisplay}
      </h3>
    </Box>
  );
}

export default Timer;

import TextsmsIcon from '@mui/icons-material/Textsms';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { follower_profile } from 'asset';
import { BsFillHandThumbsUpFill } from 'react-icons/bs';
import SecondIcon from '../../../media/Frame20452Third.png';
import firstIcon from '../../../media/GroupFirst.png';
import ThirdIcon from '../../../media/GroupFourth.png';
import FourthIcon from '../../../../../asset/fourthIcon.png';
import { useAppDispatch, useAppSelector } from 'store/store';
import { addReactionVideo } from 'store/Thunk/reactionThunk';
import { useEffect, useState } from 'react';
import { addFollowing } from 'store/Thunk/videoThunk';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import xIcon from '../../../media/x.png';
import mainicon from '../../../media/img-1.png';
import tick from '../../../media/tick.png';
import question from '../../../media/question.png';
import {
  setFollowingLoading,
  setReactionLoading,
} from 'store/Slice/videoSlice';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  position: 'absolute',
  fontSize: 18,
  right: -3,
  fontWeight: 900,
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: theme.palette.common.white,
}));

function VideoSideBar({ fetchData, video }) {
  const { user } = useAppSelector((state) => state.authReducer);
  const { followingLoading, reactionLoading } = useAppSelector(
    (state) => state.videoReducer,
  );

  const dispatch = useAppDispatch();
  const [selectedReaction, setSelectedReaction] = useState('like');
  const [isDropdown, setIsDropdown] = useState(false);

  const handleFollowing = (e) => {
    let request = {
      studentId: user?.id,
      followeeId: video?.user?.id,
    };
    dispatch(setFollowingLoading(true));
    dispatch(addFollowing(request)).then((res) => {
      dispatch(setFollowingLoading(false));
      fetchData();
    });
  };

  useEffect(() => {
    if (video?.Reaction?.length) {
      setSelectedReaction(video?.Reaction[0]?.type);
    } else {
      setSelectedReaction('like');
    }
  }, [video?.Reaction]);

  const sendReaction = (type) => {
    if (type !== 'like') {
      setIsDropdown(false);
      // setSelectedReaction(type);
    }
    dispatch(setReactionLoading(true));
    dispatch(
      addReactionVideo({
        id: video?.id,
        data: {
          type,
          reactor: user?.id,
        },
      }),
    ).then((res) => {
      dispatch(setReactionLoading(false));
      fetchData();
      // if (video?.Reaction[0]?.type === type) {
      //   setSelectedReaction('like');
      // } else {
      //   setSelectedReaction(type);
      // }
    });
  };

  const renderIcons = (selectedReaction) => {
    switch (selectedReaction) {
      case 'excited':
        return (
          <img src={firstIcon} alt="Excited" width={'28px'} height={'28px'} />
        );
      case 'laugh':
        return (
          <img src={SecondIcon} alt="Laugh" width={'28px'} height={'28px'} />
        );
      case 'sad':
        return (
          <img src={FourthIcon} alt="Sad" width={'28px'} height={'28px'} />
        );
      case 'confused':
        return (
          <img src={ThirdIcon} alt="Confused" width={'28px'} height={'28px'} />
        );
    }
  };

  return (
    <Box
      component={Paper}
      sx={{
        ml: 1,
        padding: '40px 4px',
        borderRadius: '10px !important',
        backgroundColor: '#F3F3F3',
        height: '300px',
        width: '70px',
        borderRadius: '48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      className="videoResponseButtons"
    >
      <Box>
        {video?.user?.followers?.length > 0 ? (
          <>
            {/* <CheckCircleIcon /> */}
            {followingLoading ? (
              <CircularProgress />
            ) : (
              <Badge
                overlap="circular"
                sx={{ cursor: 'pointer' }}
                badgeContent={
                  <BadgeContentSpan>
                    <DoneRoundedIcon sx={{ pb: '4px', pr: '4px' }} />
                  </BadgeContentSpan>
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Avatar
                  alt={video?.user?.name || 'A'}
                  sx={{ width: 50, height: 50 }}
                  src={
                    video?.user?.profileImage
                      ? video?.user?.profileImage
                      : follower_profile
                  }
                  onClick={handleFollowing}
                />
              </Badge>
            )}
          </>
        ) : (
          <>
            {followingLoading ? (
              <CircularProgress />
            ) : (
              <Badge
                overlap="circular"
                sx={{ cursor: 'pointer' }}
                badgeContent={<BadgeContentSpan>+</BadgeContentSpan>}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Avatar
                  className="img-user-outline"
                  alt={video?.user?.name || 'A'}
                  sx={{ width: 58, height: 58 }}
                  // src={
                  //   video?.user?.profileImage
                  //     ? video?.user?.profileImage
                  //     : follower_profile
                  // }
                  src={mainicon}
                  onClick={handleFollowing}
                />
              </Badge>
            )}
          </>
        )}
      </Box>
      <div>
        <div className="dropdown">
          {reactionLoading ? (
            <CircularProgress />
          ) : (
            <button
              className="videoResponseButton"
              style={{ width: '100%' }}
              onMouseOver={() => setIsDropdown(true)}
            >
              {selectedReaction == 'like' ? (
                <BsFillHandThumbsUpFill size={'28px'} color="#fff" />
              ) : (
                renderIcons(selectedReaction)
              )}
            </button>
          )}
          {isDropdown && (
            <div className="dropdown-content">
              <Box className="dropdown-heading">
                <Typography>Did you know this?</Typography>
              </Box>
              <Stack direction="row" justifyContent="center">
                <button
                  onClick={() => {
                    sendReaction('excited');
                  }}
                  className={
                    selectedReaction == 'excited' && 'selected_reaction'
                  }
                >
                  {/* <img src={firstIcon} alt="Like" /> */}
                  <img src={tick} alt="Like" />
                </button>
                <button
                  onClick={() => {
                    sendReaction('laugh');
                  }}
                  style={{
                    backgroundColor: '#F6C552',
                  }}
                  className={selectedReaction == 'laugh' && 'selected_reaction'}
                >
                  {/* <img src={SecondIcon} alt="Like" /> */}
                  <img
                    src={question}
                    width={'14px'}
                    height={'18px'}
                    alt="Like"
                  />
                </button>
                <button
                  onClick={() => {
                    sendReaction('sad');
                  }}
                  style={{
                    backgroundColor: '#FA2525',
                  }}
                  className={selectedReaction == 'sad' && 'selected_reaction'}
                >
                  {/* <img
                      src={FourthIcon}
                      alt="Like"
                      width={'32px'}
                      height={'32px'}
                    /> */}
                  <img src={xIcon} alt="Like" width={'18px'} height={'18px'} />
                </button>
              </Stack>
              {/* <button
                onClick={() => {
                  sendReaction('confused');
                }}
                className={
                  selectedReaction == 'confused' && 'selected_reaction'
                }
              >
                <img src={ThirdIcon} alt="Like" />
              </button> */}
            </div>
          )}
        </div>
      </div>
      <button
        className="videoResponseButton"
        style={{ filter: 'grayscale(1)' }}
      >
        <TextsmsIcon size={'28px'} color="#fff" sx={{ color: 'white' }} />
      </button>
    </Box>
  );
}

export default VideoSideBar;

const VideoActionsCss = {
  IconButton: {
    background: '#20A58A',
    m: 2,
    color: 'white',
    '&:hover': {
      background: '#20A58A',
    },
  },
};

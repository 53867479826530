import { NotificationManager } from 'react-notifications';
import API from 'store/api';

export const createStudentVideoActivityReq = async (request) => {
  let url = `api/v1/student-activity/video-watch/${request.userId}`;
  try {
    let _request = {
      videoId: request.videoId,
      watchedAt: request.watchedAt,
      completed: request.completed,
      timeWatched: request.timeWatched,
    };
    const response = await API.post(url, _request);
    return response.data.data;
  } catch (err) {
    NotificationManager.error(err.response.data.message, 'Error', 3000);
    return err;
  }
};

export const updateStudentVideoActivityReq = async (request) => {
  const { id, ...rest } = request;
  let url = `api/v1/student-activity/video-watch/${id}`;
  try {
    let _request = {
      ...rest,
    };
    const response = await API.patch(url, _request);

    return response.data.data;
  } catch (err) {
    NotificationManager.error(err.response.data.message, 'Error', 3000);
    return err;
  }
};

export const getRelatedSkillVideosReq = async (request) => {
  const { id, skill, ...rest } = request;
  let url = `api/v1/videos/skill/${id}?skill=${skill}`;
  try {
    let _request = {
      ...rest,
    };
    const response = await API.get(url, _request);
    return response.data.data;
  } catch (err) {
    NotificationManager.error(err.response.data.message, 'Error', 3000);
    return err;
  }
};

export const getSudentVideoActivityReq = async (_request) => {
  let url = `api/v1/student-activity/video-watch/${_request.userId}`;
  try {
    const response = await API.get(url);
    return response.data.data;
  } catch (err) {
    NotificationManager.error(err.response.data.message, 'Error', 3000);
    return err;
  }
};

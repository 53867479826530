import React, { useCallback, useEffect, useState } from 'react';
import sharedStyles from '../../../sharedStyle.module.scss';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Logo from '../../../components/logo.png';
import AddSchoolDialog from '../dialog/AddSchool';
import ConfirmSchool from '../dialog/ConfirmAddSchool';
import API from 'store/api';
import { completeProfile } from 'store/Thunk/registerThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { NotificationManager } from 'react-notifications';
import axios from 'api/axios';
import { setUserDetails } from 'store/Slice/authSlice';
import { buildStyles } from 'react-circular-progressbar';

export default function ProfileDetail({ setStep }) {
  const { user } = useAppSelector((state) => state.authReducer);

  const [loading, setLoading] = useState(false);

  const [schoolCode, setSchoolCode] = useState(true);
  const [openSchoolDialog, setOpenSchoolDialog] = useState(false);
  const [openConfirmSchool, setOpenConfirmSchool] = useState(false);
  const [openRole, setOpenRole] = useState(false);
  const [openReferralSource, setOpenReferralSource] = useState(false);
  const [openGrades, setOpenGrades] = useState(false);
  const [openStates, setOpenStates] = useState(false);
  const [openCities, setOpenCities] = useState(false);
  const [openSchool, setOpenSchool] = useState(false);
  const roleMap = [
    {
      id: 'student',
      name: 'Student',
    },
    {
      id: 'teacher',
      name: 'Teacher',
    },
    {
      id: 'parent',
      name: 'Parent',
    },
    {
      id: 'school_leader',
      name: 'School Leader',
    },
    {
      id: 'district_leader',
      name: 'District Leader',
    },
    {
      id: 'private_tutor',
      name: 'Private Tutor',
    },
    {
      id: 'tutor_company',
      name: 'Tutor Company',
    },
    {
      id: 'tutor',
      name: 'Tutor',
    },
    {
      id: 'other',
      name: 'Other',
    },
  ];
  const referralSourceList = [
    'Tiktok',
    'Instagram',
    'Facebook',
    'Twitter',
    'Google',
    'Friend',
    'Other',
  ];
  const [schools, setSchools] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    (async () => {
      setLoadingStates(true);
      try {
        const response = await API.get('/api/v1/csc/states');
        setStates(response.data.data);
        setLoadingStates(false);
      } catch (error) {
        setLoadingStates(false);
        console.log(error);
      }
    })();
  }, []);

  const onCloseConfirm = () => {
    setOpenConfirmSchool(false);
  };
  const onClose = () => {
    setOpenSchoolDialog(false);
    setOpenConfirmSchool(true);
  };
  const onCloseSchool = () => {
    setOpenSchoolDialog(false);
  };
  const handleOnchange = (value) => {
    const isTrue = value === 'true';
    setSchoolCode(isTrue);
  };
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState([]);
  const [loadingSchool, setLoadingSchool] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const handleChangeAutocomplete = (name) => (event, newValue) => {
    let param = {
      [name]: newValue,
    };
    setFormData({ ...formData, ...param });
  };

  useEffect(() => {
    if (!formData?.state?.id) return;
    (async () => {
      setLoadingCities(true);
      try {
        const response = await API.get(
          `/api/v1/csc/cities?stateId=${formData?.state?.id}`,
        );
        setCities(response.data.data);
        setLoadingCities(false);
      } catch (error) {
        setLoadingCities(false);
        console.log(error);
      }
    })();
  }, [formData?.state]);

  useEffect(() => {
    if (!formData?.city?.id) return;
    (async () => {
      setLoadingSchool(true);
      try {
        const response = await API.get(
          `/api/v1/schools?cityId=${formData?.city?.id}`,
        );
        setSchools(
          response.data.data.items.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        );
        setLoadingSchool(false);
      } catch (error) {
        setLoadingSchool(false);
        console.log(error);
      }
    })();
  }, [formData?.city]);

  const dispatch = useAppDispatch();

  const [grades, setGrades] = useState([]);

  useEffect(() => {
    axios.get('/grades').then((response) => {
      setGrades(
        response.data.data.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      );
    });
  }, []);

  async function AdminSignIn(user) {
    axios
      .post('/users/login', {
        email: user.email,
        firebaseId: user.uid,
        userType: user.type,
      })
      .then(async (res) => {
        const token = res.data.token;
        console.log('================/users/login===============', res);
        localStorage.setItem('uuid', res.data.id);
        localStorage.setItem('userData', JSON.stringify(res.data));
        localStorage.setItem('accessToken', token);
        dispatch(setUserDetails(res.data));
      })
      .catch((err) => {
        console.log('err', err);
        NotificationManager.error(err?.response?.data?.message, 'Error', 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleNext = useCallback(() => {
    // Clear form error
    const errors = {};

    if (!formData?.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!formData?.lastName) {
      errors.lastName = 'Last name is required';
    }
    if (
      formData?.role?.id &&
      formData?.role?.id === 'student' &&
      !user?.username &&
      !formData?.username
    ) {
      errors.username = 'Username is required';
    }
    if (!formData?.referralSource) {
      errors.referralSource = 'How did you hear about G3MS is required';
    }
    if (!formData?.role) {
      errors.role = 'Role is required';
    } else {
      if (formData?.role?.id === 'student' && !formData?.grade) {
        errors.grade = 'Grade is required';
      }
    }
    if (!schoolCode) {
      if (
        !formData?.schoolName &&
        !formData?.schoolAddress &&
        !formData?.schoolType
      ) {
        if (!formData?.state) {
          errors.state = 'State is required';
        }
        if (!formData?.city) {
          errors.city = 'City is required';
        }
        if (!formData?.school) {
          errors.school = 'School is required';
        }
      }
    } else {
      if (!formData?.schoolCode) {
        errors.schoolCode = 'School code is required';
      }
    }
    setFormError(errors);
    if (!Object.keys(errors).length) {
      setLoading(true);
      dispatch(
        completeProfile({
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          username: user?.username ?? formData?.username,
          referralSource: formData?.referralSource,
          role: formData?.role?.id,
          schoolId: schoolCode ? formData?.schoolCode : formData?.school?.id,
          gradeId: formData?.grade?.id,
        }),
      )
        .unwrap()
        .then((data) => {
          setLoading(false);
          localStorage.setItem('userData', JSON.stringify(data.data.user));
          if (data.data.user.type === 'student') {
            dispatch(setUserDetails(data.data.user));
            NotificationManager.success(
              'Profile completed successfully',
              'Success',
            );
          } else {
            const uid = localStorage.getItem('fuid');
            AdminSignIn({
              email: data.data.user.email,
              uid: uid,
              type: data.data.user.type,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.message) {
            if (typeof error?.message === 'string') {
              NotificationManager.error(error?.message, 'Error', 3000);
            } else {
              for (let key in error?.message) {
                NotificationManager.error(error?.message[key], 'Error', 3000);
              }
            }
          }
        });
    }
  }, [formData, schoolCode, dispatch, user?.username]);

  return (
    <Box
      className={`${sharedStyles.profileAvatarContainer} ${sharedStyles.profileDetailsContainer}`}
    >
      <Box className={sharedStyles.avatarLogo}>
        <img src={Logo} alt={'logo'} />
      </Box>
      <Box
        className={`${sharedStyles.avatar} ${sharedStyles.detailsContainer}`}
      >
        <Stack
          gap={4}
          sx={{
            width: '100%',
            '& .MuiTypography-root': {
              mb: 2,
              textAlign: 'center',
              fontSize: '1.3vw',
            },
            '& .MuiTextField-root': {
              width: '100%',
              fontSize: '18px',
              fontWeight: 500,
            },
            '& .MuiOutlinedInput-input': {
              width: '100%',
              fontSize: '18px',
              fontWeight: 500,
            },
          }}
        >
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl"
            mb={0}
            style={{ color: '#131515' }}
          >
            Tell us more about you!
          </Typography>
          <Box className="flex gap-4">
            <Stack flex={1} alignItems={'flex-start'} sx={{ '': {} }}>
              <Typography
                className="!font-semibold !text-lg sm:!text-xl"
                style={{ color: '#000' }}
              >
                First Name
              </Typography>
              <TextField
                placeholder="First name"
                className="!text-sm sm:!text-xl"
                id="my-textfield"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                error={formError && formError?.firstName}
                helperText={formError && formError?.firstName}
              />
            </Stack>
            <Stack flex={1} alignItems={'flex-start'}>
              <Typography
                className="!font-semibold !text-lg sm:!text-xl"
                style={{ color: '#000' }}
              >
                Last Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Last name"
                id="my-textfield"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                error={formError && formError?.lastName}
                helperText={formError && formError?.lastName}
              />
            </Stack>
          </Box>
          <Stack width={'100%'} alignItems={'flex-start'}>
            <Typography
              className="!font-semibold !text-lg sm:!text-xl"
              style={{ color: '#000' }}
            >
              Role
            </Typography>
            <Box className="flex-1 w-full">
              <Autocomplete
                id="role"
                open={openRole}
                size="small"
                onOpen={() => setOpenRole(true)}
                onClose={() => setOpenRole(false)}
                isOptionEqualToValue={(option, value) => option.id === value}
                getOptionLabel={(option) => option.name || ''}
                options={roleMap}
                filterSelectedOptions
                value={formData?.role}
                onChange={(event, value) => {
                  handleChangeAutocomplete('role')(event, value);
                }}
                renderOption={(props, option, state) => (
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.selected} // Checkbox is checked for the selected option
                          onChange={() => {
                            // Handle the checkbox change event
                            handleChangeAutocomplete('role')(
                              null,
                              state.selected ? null : option,
                            );
                          }}
                        />
                      }
                      label={option.name}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Role"
                    error={formError && formError?.role}
                    helperText={formError && formError?.role}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingSchool ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {formData?.role?.id === 'student' && (
                <Stack width={'100%'} alignItems={'flex-start'}>
                  <Typography
                    className="!font-semibold !text-lg sm:!text-xl"
                    style={{ color: '#000' }}
                  >
                    Grade
                  </Typography>
                  <Box className="flex-1 w-full">
                    <Autocomplete
                      id="grade"
                      open={openGrades}
                      size="small"
                      onOpen={() => setOpenGrades(true)}
                      onClose={() => setOpenGrades(false)}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      getOptionLabel={(option) => option.name || ''}
                      options={grades}
                      filterSelectedOptions
                      value={formData?.grade}
                      onChange={(event, value) => {
                        handleChangeAutocomplete('grade')(event, value);
                      }}
                      renderOption={(props, option, state) => (
                        <li {...props}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.selected} // Checkbox is checked for the selected option
                                onChange={() => {
                                  // Handle the checkbox change event
                                  handleChangeAutocomplete('grade')(
                                    null,
                                    state.selected ? null : option,
                                  );
                                }}
                              />
                            }
                            label={option.name}
                          />
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Grade"
                          error={formError && formError?.grade}
                          helperText={formError && formError?.grade}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </Stack>
              )}
            </Grid>
            <Grid item xs={6}>
              {formData?.role?.id &&
                formData?.role?.id === 'student' &&
                !user?.username && (
                  <Stack alignItems={'flex-start'}>
                    <Typography
                      className="!font-semibold !text-lg sm:!text-xl"
                      style={{ color: '#000' }}
                    >
                      Username
                    </Typography>
                    <TextField
                      placeholder="Create a unique username"
                      id="my-textfield"
                      variant="outlined"
                      size="small"
                      onChange={(e) =>
                        setFormData({ ...formData, username: e.target.value })
                      }
                      error={formError && formError?.username}
                      helperText={formError && formError?.username}
                    />
                  </Stack>
                )}
            </Grid>
          </Grid>
          <Stack alignItems={'flex-start'}>
            <Typography
              className="!font-semibold !text-lg sm:!text-xl"
              style={{ color: '#000' }}
            >
              How did you hear about G3MS?
            </Typography>
            <Box className="flex-1 w-full">
              <Autocomplete
                id="referralSource"
                open={openReferralSource}
                size="small"
                onOpen={() => setOpenReferralSource(true)}
                onClose={() => setOpenReferralSource(false)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option || ''}
                options={referralSourceList}
                filterSelectedOptions
                value={formData?.referralSource}
                onChange={(event, value) => {
                  handleChangeAutocomplete('referralSource')(event, value);
                }}
                renderOption={(props, option, state) => (
                  <li {...props}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.selected} // Checkbox is checked for the selected option
                          onChange={() => {
                            // Handle the checkbox change event
                            handleChangeAutocomplete('referralSource')(
                              null,
                              state.selected ? null : option,
                            );
                          }}
                        />
                      }
                      label={option}
                    />
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Tiktok"
                    error={formError && formError?.referralSource}
                    helperText={formError && formError?.referralSource}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            {/* <TextField
              placeholder="Tiktok"
              id="my-textfield"
              variant="outlined"
              size="small"
              onChange={(e) => setFormData({ ...formData, referralSource: e.target.value })}
              error={formError && formError?.referralSource}
              helperText={formError && formError?.referralSource}
            /> */}
          </Stack>
          <Stack alignItems={'flex-start'}>
            <Typography
              className="!font-semibold !text-lg sm:!text-xl"
              style={{ color: '#000' }}
            >
              Do you have a school code?
            </Typography>
            <FormControl>
              <RadioGroup
                onChange={(e) => handleOnchange(e.target.value)}
                value={schoolCode}
                row
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '16px',
                  },
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          {schoolCode ? (
            <Stack alignItems={'flex-start'}>
              <Typography
                className="!font-semibold !text-lg sm:!text-xl"
                style={{ color: '#000' }}
              >
                School Code
              </Typography>
              <TextField
                placeholder="Enter school code"
                id="my-textfield"
                variant="outlined"
                size="small"
                value={formData?.schoolCode || ''}
                onChange={(e) =>
                  setFormData({ ...formData, schoolCode: e.target.value })
                }
                error={formError && formError?.schoolCode}
                helperText={formError && formError?.schoolCode}
              />
            </Stack>
          ) : (
            <Box className="flex gap-4">
              <Stack flex={1} alignItems={'flex-start'}>
                <Typography
                  className="!font-semibold !text-lg sm:!text-xl"
                  style={{ color: '#000' }}
                >
                  State
                </Typography>

                <Autocomplete
                  id="states"
                  open={openStates}
                  size="small"
                  onOpen={() => setOpenStates(true)}
                  onClose={() => setOpenStates(false)}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value;
                  }}
                  getOptionLabel={(option) => {
                    return option.name || '';
                  }}
                  sx={{ width: '100%' }}
                  options={states}
                  filterSelectedOptions
                  value={formData?.state}
                  disableClearable
                  onChange={(event, value) => {
                    setCities([]);
                    setFormData({ ...formData, city: null });
                    handleChangeAutocomplete('state')(event, value);
                  }}
                  renderOption={(props, option, state) => (
                    <li {...props}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.selected} // Checkbox is checked for the selected option
                            onChange={() => {
                              // Handle the checkbox change event
                              setCities([]);
                              setFormData({ ...formData, city: null });
                              handleChangeAutocomplete('state')(
                                null,
                                state.selected ? null : option,
                              );
                            }}
                          />
                        }
                        label={option.name}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="What state is your school in?"
                      error={formError && formError?.state}
                      helperText={formError && formError?.state}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStates ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Stack>
              <Stack flex={1} alignItems={'flex-start'}>
                <Typography
                  className="!font-semibold !text-lg sm:!text-xl"
                  style={{ color: '#000' }}
                >
                  City
                </Typography>
                {/* <TextField
                  fullWidth
                  placeholder="What city is your school in?"
                  id="my-textfield"
                  variant="outlined"
                  size="small"
                /> */}

                <Autocomplete
                  id="cities"
                  open={openCities}
                  size="small"
                  onOpen={() => setOpenCities(true)}
                  onClose={() => setOpenCities(false)}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.name || ''}
                  sx={{ width: '100%' }}
                  options={cities}
                  filterSelectedOptions
                  value={formData?.city}
                  onChange={(event, value) => {
                    handleChangeAutocomplete('city')(event, value);
                  }}
                  renderOption={(props, option, state) => (
                    <li {...props}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.selected} // Checkbox is checked for the selected option
                            onChange={() => {
                              // Handle the checkbox change event
                              handleChangeAutocomplete('city')(
                                null,
                                state.selected ? null : option,
                              );
                            }}
                          />
                        }
                        label={option.name}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="What city is your school in?"
                      error={formError && formError?.city}
                      helperText={formError && formError?.city}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCities ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Stack>
            </Box>
          )}
          {!schoolCode && (
            <Stack alignItems={'flex-start'}>
              <Box className="flex justify-between w-full">
                <Typography
                  className="!font-semibold !text-lg sm:!text-xl"
                  style={{ color: '#000' }}
                >
                  Select your school/company
                </Typography>
                <Typography
                  className="!font-semibold !text-lg sm:!text-xl"
                  style={{ color: '#C73E9D' }}
                >
                  Add School/Company
                </Typography>
              </Box>
              <Box className="flex-1 w-full">
                <Autocomplete
                  id="school"
                  size="small"
                  open={openSchool}
                  onOpen={() => setOpenSchool(true)}
                  onClose={() => setOpenSchool(false)}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  getOptionLabel={(option) => option.name || ''}
                  options={[
                    ...schools,
                    {
                      id: '0',
                      name: 'My school/company is NOT LISTED',
                    },
                  ]}
                  filterSelectedOptions
                  defaultValue="Select your school/company or click to ADD it "
                  value={formData?.school || ''}
                  onChange={(event, value) => {
                    if (value.name === 'My school/company is NOT LISTED') {
                      // Handle the event when the option is checked
                      // You can perform any desired action here
                      setOpenSchoolDialog(true);
                      setFormData({
                        ...formData,
                        schoolName: null,
                        schoolAddress: null,
                        schoolType: null,
                      });
                    } else {
                      handleChangeAutocomplete('school')(event, value);
                    }
                  }}
                  renderOption={(props, option, state) => (
                    <li {...props}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.selected} // Checkbox is checked for the selected option
                            onChange={() => {
                              // Handle the checkbox change event
                              handleChangeAutocomplete('school')(
                                null,
                                state.selected ? null : option,
                              );
                            }}
                          />
                        }
                        label={option.name}
                      />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select your school/company or click to ADD it"
                      error={formError && formError?.school}
                      helperText={formError && formError?.school}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingSchool ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>
          )}
        </Stack>

        <CircularProgress
          size={30}
          style={{
            display: loading ? 'block' : 'none',
            margin: 'auto',
            marginTop: '20px',
          }}
          styles={buildStyles({
            pathColor: '#C73E9D',
          })}
        />

        <Button
          variant={'contained'}
          size="large"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
      <AddSchoolDialog
        open={openSchoolDialog}
        onClose={onClose}
        onCloseDialog={onCloseSchool}
        formData={formData}
        setFormData={setFormData}
        handleChangeAutocomplete={handleChangeAutocomplete}
      />
      <ConfirmSchool
        open={openConfirmSchool}
        onClose={onCloseConfirm}
        formData={formData}
        setFormData={setFormData}
        setSchoolCode={setSchoolCode}
      />
    </Box>
  );
}

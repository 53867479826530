// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';
import CircularProgressWithLabel from '@core/components/CircularWithValueLabel';

// ** Components
// import Autocomplete from 'layouts/components/Autocomplete'
import ModeToggler from '@core/layouts/components/shared-components/ModeToggler';
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import LanguageDropdown from '@core/layouts/components/shared-components/LanguageDropdown';
import NotificationDropdown from '@core/layouts/components/shared-components/NotificationDropdown';
import TopicTab from 'app/student/components/main/elements/assignments/topicTab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SelectAssignmentList from 'app/student/components/main/elements/assignments/selectAssignmentList';
import { Grid, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'store/store';
import Timer from '../timer';

const AppBarContent = (props) => {
  const location = useLocation();
  const data = location?.state;
  const { startQuiz } = useAppSelector((state) => state.quizReducer);
  const { quizLoading } = useAppSelector((state) => state.quizReducer);
  const { hideTimer } = useAppSelector((state) => state.quizReducer);

  const [videoTopic, setVideoTopic] = useState(data ? data : '');
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  // Get the current URL
  const currentURL = window.location.href;

  // Words to check for in the URL
  const wordsToCheck = 'daily-quiz';

  // Check if any of the words are present in the URL
  const hasWords = currentURL.includes(wordsToCheck);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
      >
        <div>
          <h1
            style={{
              color: '#131515',
              fontSize: '1.5rem',
              fontWeight: 600,
            }}
          >
            Earn $G3MS Daily Practice!
          </h1>
          <SelectAssignmentList
            videoTopic={videoTopic}
            setVideoTopic={setVideoTopic}
          />
        </div>

        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      </Box>
      <Stack>
        <Box
          className="actions-right"
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <UserDropdown settings={settings} />
        </Box>
        <Stack>
          {startQuiz && hasWords && !quizLoading && !hideTimer && <Timer />}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AppBarContent;

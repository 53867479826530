import { createSlice } from '@reduxjs/toolkit';

const userLocal = localStorage.getItem('userData')
  ? JSON.parse(localStorage.getItem('userData'))
  : null;
const intialState = {
  user: userLocal,
};

const AuthSlice = createSlice({
  name: 'auth_slice',
  initialState: intialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state, action) => {
      state.user = undefined;
    },
  },
});

export const { setUserDetails, logoutUser } = AuthSlice.actions;

export default AuthSlice.reducer;

import API from 'store/api';

export const getQuizListApi = async (_request) => {
  let url = `/api/v1/assignment/student/${_request.userId}/request/template?skillId=${_request.skillId}&classroomId=${_request.classroomId}`;
  try {
    const response = await API.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

import moment from 'moment-timezone';

export const getLocalDateTime = (date, format = 'MMMM D, YYYY, HH:mm') => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  // const timezone = getUserTimeZone(null);
  // if (timezone) {
  //   return moment.tz(d, timezone).format(format);
  // }
  return moment(d).format(format);
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import quizReducer from './Slice/quizSlice';
import topicReducer from './Slice/topicSlice';
import videoReducer from './Slice/videoSlice';
import authReducer from './Slice/authSlice';
import registerReducer from './Slice/registerSlice';
import userReducer from './Slice/userSlice';
import teacherReducer from './Slice/teacherSlice';
import schoolAssignmentReducer from './Slice/schoolAssignmentSlice';

import { useDispatch, useSelector } from 'react-redux';

const combinedReducer = combineReducers({
  quizReducer,
  topicReducer,
  videoReducer,
  authReducer,
  registerReducer,
  userReducer,
  teacherReducer,
  schoolAssignmentReducer
});

export const store = configureStore({
  reducer: combinedReducer,
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export default store;

import { useEffect, useState } from 'react';
import styles from './selectAssignment.module.scss';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'api/hooks';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getStudentTopics } from 'store/Thunk/topicThunk';
import appStore from '../../../../media/app-store.png';
import googlePlay from '../../../../media/play-store.png';

const SelectAssignmentList = ({ setVideoTopic, data }) => {
  const [topic, setTopic] = useState('');
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const query = useQuery();
  const topicId = query.get('topic');

  const { studentTopicList } = useAppSelector((state) => state.topicReducer);
  const { user } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(getStudentTopics(user?.id));
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (topicId) {
      setTopic(topicId);
    } else {
      setTopic('');
    }
  }, [topicId]);

  const handleTopicNavigation = (item) => {
    const currentPath = '/app/student';
    const willBeRoute = currentPath.includes('leaderboard')
      ? '/app/student/leaderboard/'
      : currentPath;
    navigate(`${willBeRoute}?topic=${item.id}`);
  };

  return (
    <div className={styles.selectAssignmentList} id="selectAssignmentList">
      <button
        className={`${styles.item} ${topic === '' ? styles.selected : ''}`}
        onClick={() => {
          setVideoTopic('');
          if (topicId === "") {
            navigate(`/app/student`);
          } else {
            navigate(`/app/student?topic=`);
          }
        }}
      >
        Surprise Me!
      </button>
      {data
        ? data
        : studentTopicList.map((item, index) => {
          return (
            <button
              key={index}
              className={`${styles.item} ${topic === item.id ? styles.selected : ''
                }`}
              onClick={() => {
                setVideoTopic(item.id);
                handleTopicNavigation(item);
              }}
            >
              {item.name}
            </button>
          );
        })}
        <a href="https://apps.apple.com/us/app/g3ms/id1625313492" target="_blank" rel="noreferrer">
          <img src={appStore} alt="app store" className={styles.appStore} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.g3ms" target="_blank" rel="noreferrer">
          <img src={googlePlay} alt="app store" className={styles.googlePlay} />
        </a>
    </div>
  );
};

export default SelectAssignmentList;

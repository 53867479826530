import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { IncorrectIcon } from 'asset';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setParentEmail, setParentPhone } from 'store/Slice/registerSlice';
import { isEmail, isPhone } from '@core/utils/validators';

export default function ParentsDialog({ open, onClose, setStep }) {
  const dispatch = useAppDispatch();
  const { parentEmail, parentPhone } = useAppSelector(
    (state) => state.registerReducer,
  );

  function onNext() {
    // check if email is valid regex
    if (!isPhone(parentPhone)) {
      NotificationManager.error('Please enter a valid phone number', 'Error');
      return;
    }
    if (!isEmail(parentEmail)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }
    setStep((prevStep) => ({
      ...prevStep,
      current: 'signup',
    }));
  }
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle
        className="!font-semibold !text-3xl sm:!text-3xl md:!text-2xl lg:!text-3xl"
        sx={{ color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
      >
        Ask a Parent?
      </DialogTitle>
      <DialogContent>
        <Box
          textAlign={'center'}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-input': { fontSize: '18px', fontWeight: 500 },
          }}
        >
          <FormControl
            variant="outlined"
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-input': { paddingTop: '50px' },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
              style={{
                marginTop: '20px',
                fontWeight: 600,
                color: '#130F26',
              }}
            >
              What is your parent's email?
            </InputLabel>
            <TextField
              placeholder="Type your parents email"
              id="my-textfield"
              variant="outlined"
              style={{
                paddingTop: '30px !important',
                fontSize: '18px',
                fontWeight: 500,
              }}
              sx={{ paddingTop: '30px !important' }}
              onChange={(e) => dispatch(setParentEmail(e.target.value))}
              value={parentEmail || ''}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-input': { paddingTop: '50px' },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
              style={{
                marginTop: '20px',
                fontWeight: 600,
                color: '#130F26',
              }}
            >
              What is your parent’s phone number?
            </InputLabel>
            <TextField
              placeholder="(305) 803 - 3500"
              id="my-textfield"
              variant="outlined"
              style={{
                paddingTop: '30px !important',
                fontSize: '18px',
                fontWeight: 500,
              }}
              sx={{ paddingTop: '30px !important' }}
              onChange={(e) => dispatch(setParentPhone(e.target.value))}
              value={parentPhone || ''}
            />
          </FormControl>

          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            sx={{
              my: 10,
              color: '#000',
              lineHeight: 1,
            }}
          >
            You can still join us, but before you start using the camera or
            claiming your rewards, we'll need a grownup's okay.
          </Typography>
          <Box
            mt={4}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              size="large"
              sx={{
                mr: 2,
                width: '90%',
                minWidth: '120px',
                '&.MuiButtonBase-root:hover': { backgroundColor: '#C73E9D' },
              }}
              variant="contained"
              onClick={onNext}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

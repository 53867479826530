import React from 'react';
import './index.css';
import { AppStore, PlayStore } from 'asset';
import { Paper, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Logo from '../components/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'store/Slice/authSlice';

import { useDispatch } from 'react-redux';
export default function WebRedirect() {
  const handleDownload = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('uuid');
    localStorage.removeItem('header');
    dispatch(logoutUser());
    navigate('/');
  };
  return (
    <div className="force-modal">
      <div className="appbar-container">
        <AppBar position="static" color="transparent">
          <Toolbar
            sx={{
              display: 'flex',
              padding: '20px',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <img src={Logo} style={{ height: '60px' }} alt={'logo'} />
            </Box>
            <IconButton
              onClick={handleLogout}
              sx={{ fontSize: '30px' }}
              aria-label="delete"
            >
              <LogoutIcon sx={{ fontSize: '30px' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
      <Paper elevation={5}>
        <div className="modal-content">
          <h2>Download Our Mobile App</h2>
          <p className="modal-content-subtitle">
            Looks like you are on mobile device and for the best experience
            click below to download our native iOS or android app.
          </p>
          <div className="app-links">
            <Stack
              onClick={() =>
                handleDownload(
                  'https://apps.apple.com/us/app/g3ms/id1625313492',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-0"
            >
              <img src={AppStore} alt="Download on the App Store" />

              <p className="download-links">Download on the App Store</p>
            </Stack>
            <Stack
              onClick={() =>
                handleDownload(
                  'https://play.google.com/store/apps/details?id=com.g3ms',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-0"
            >
              <img src={PlayStore} alt="Get it on Google Play" />

              <p className="download-links">Download on the App Store</p>
            </Stack>
          </div>
        </div>
      </Paper>
    </div>
  );
}

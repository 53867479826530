import {useState} from "react";
import styles from "./modal.module.scss"

const Modal = ({open, setOpen, children}) => {

    if (!open)
        return (<> </>)

    return (
        <>
            <div onClick={() => {
                setOpen(false)
            }} className={styles.overlay}/>
            <div className={styles.content}>
                {children}
            </div>
        </>
    )
}

export default Modal;
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircularProgressWithLabel from '@core/components/CircularWithValueLabel';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useState } from 'react';
import { getQuizList } from 'store/Thunk/quizThunk';
import { useEffect } from 'react';
import EmptyQuiz from 'app/student/components/emptyQuiz';
import { setQuizLoading, setStartQuiz } from 'store/Slice/quizSlice';
import { useQuery } from 'api/hooks';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';

const Quiz = ({ videoTopic }) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { quizList, quizLoading } = useAppSelector(
    (state) => state.quizReducer,
  );
  const { user } = useAppSelector((state) => state.authReducer);
  const { userData } = useAppSelector((state) => state.userReducer);

  const [template, setTemplate] = useState({});
  console.log('template', template);
  console.log('template', quizList);
  console.log('template', quizLoading);

  function removeSequenceNumber(text) {
    const arrs = text.split(':');
    return arrs[arrs.length - 1];
  }
  useEffect(() => {
    if (user?.id) {
      console.log('::RAN THIS QUIZ AGAIN<============');
      dispatch(setQuizLoading(true));
      dispatch(
        getQuizList({
          topic: videoTopic,
          userId: user?.id,
          gradeId:
            userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      )
        .unwrap()
        .then((res) => {
          console.log('<============Done loading quiz list<============', res);
          dispatch(setQuizLoading(false));
        })
        .catch((err) => {
          console.log(
            '<============Error loading quiz list<============',
            err,
            err.payload,
          );
        });
    }
    // }, [videoTopic, user?.id, userData?.grade]);
  }, []);

  const query = useQuery();
  const topic = query.get('topic');

  useEffect(() => {
    setTemplate(quizList);
  }, [quizList, topic]);

  const handleStart = () => {
    navigate(`/app/student/daily-quiz?quiz=${template.templateId}`, {
      state: videoTopic,
    });
    dispatch(setStartQuiz(true));
  };
  const getG3MSPercentage = () => {
    let percentage = 0;
    if (user && Object.keys(user).length) {
      const g3msScore = user?.gemsTokens || 0;
      const totalG3MS = user?.totalGems || 0;

      percentage = (g3msScore * 100) / totalG3MS;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  let templateObj = null;
  template?.templates?.forEach((temp) => {
    if (temp.template.id === template?.questions[0]?.templateId) {
      templateObj = temp.template;
    }
  });

  return (
    <>
      {quizLoading ? (
        <Box
          height={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          textAlign={'center'}
          px={4}
          py={10}
          sx={{
            width: '100%',
            border: '6px solid #4CBFA7',
            borderRadius: '14px',
          }}
          backgroundColor={'#F9F9F9'}
          height={'100%'}
        >
          {template && Object.keys(template).length && !template?.message ? (
            <>
              {
                <Box
                  display="flex"
                  justifyContent={'end'}
                  sx={{ height: '10%' }}
                >
                  <Typography
                    className="!text-sm sm:!text-lg md:!text-3xl"
                    sx={{ fontWeight: 600, mr: 2 }}
                    gutterBottom
                  >
                    {`${template?.currentQuestion || 0}/${
                      template?.totalQuestions || 0
                    }`}
                  </Typography>
                </Box>
              }
              <Box
                width={'90%'}
                alignSelf={'center'}
                mt={2}
                sx={{ height: '20%' }}
              >
                <Typography
                  sx={{ fontWeight: 600, color: '#131515' }}
                  gutterBottom
                  className="!text-lg sm:!text-lg md:!text-3xl"
                >
                  {removeSequenceNumber(
                    template?.quizQuestion?.question?.message || 'Math facts',
                  )}
                </Typography>
                {/* <Typography
                  className="!text-lg sm:!text-lg md:!text-3xl"
                  gutterBottom
                >
                  {template?.quizQuestion?.question?.message}
                </Typography> */}
              </Box>
              <Box
                sx={{
                  height: '50%',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  alignSelf: 'center',
                  padding: '12px',
                  borderRadius: '12px',
                  backgroundColor: template?.templateColorCode
                    ? `${
                        template?.templateColorCode.startsWith('#')
                          ? ''
                          : '#'
                      }${template?.templateColorCode}`
                    : '#FFFFFF',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className="!text-lg sm:!text-lg md:!text-3xl"
                  sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    // textDecoration: 'underline',
                    p: 1,
                  }}
                >
                  {template?.quizQuestion?.question?.question ? (
                    // <AutoFonts text={processedSentence} maxfontSize={30} />
                    <Latex>{template?.quizQuestion?.question?.question}</Latex>
                  ) : (
                    ''
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  height: '20%',
                  mx: 4,
                  mt: 1,
                  p: 1,
                  display: 'flex',
                  direction: 'coulmn',
                  justifyContent: 'center',
                  gap: '10%',
                }}
              >
                <Box className="flex flex-col justify-center items-center">
                  <CircularProgressWithLabel
                    size="5rem"
                    progressColor="#C73E9D"
                    remainProgressColor="#FAEAF5"
                    value={getG3MSPercentage()}
                    label={user?.gemsTokens || 0}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      textAlign: 'center',
                      color: '#C73E9D',
                    }}
                    variant="subtitle1"
                  >
                    $G3MS
                  </Typography>
                </Box>
                <Box className="flex flex-col justify-center items-center">
                  <CircularProgressWithLabel
                    timer
                    size="5rem"
                    progressColor="#4CBFA7"
                    remainProgressColor="#E4F2EE"
                    value={(template?.questions?.length || 1) * 60}
                  />
                  <Typography
                    sx={{ fontWeight: 700, textAlign: 'center' }}
                    variant="subtitle1"
                  >
                    Time
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                mt={4}
                // sx={{ height: '20%' }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ width: '40%' }}
                  size="large"
                  onClick={handleStart}
                >
                  Start
                </Button>
              </Box>
            </>
          ) : (
            <Box
              container
              justifyContent="space-evenly"
              alignItems="center"
              display={'flex'}
              height={'100%'}
              width={'100%'}
              gap={2}
            >
              <EmptyQuiz width={100} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Quiz;

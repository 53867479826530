import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationManager } from 'react-notifications';
import {
  setFollowingLoading,
  setVideoList,
  setVideoLoading,
  setVideoActivityList,
  updateVideoActivityList,
  removeVideoActivityList,
} from 'store/Slice/videoSlice';
import API from 'store/api';

export const getAllVideoList = createAsyncThunk(
  'getQuizList',
  async (_request, { dispatch }) => {
    const page = _request?.pageIndex || 0;
    const size = _request?.pageSize || 1;
    const userId = _request?.userId || 1;
    const gradeId = _request?.gradeId || '';
    const topic = _request?.topic;
    let url = `api/v1/assignment/video/${userId}?limit=${size}&userId=${userId}`;
    let url1 = `api/v1/student-activity/video-watch/${_request.userId}`;

    try {
      if (topic) {
        url += `&topic=${topic}`;
      }
      if (gradeId) {
        url += `&grade=${gradeId}`;
      }

      // const res = await Promise.all[(API.get(url), API.get(url1))];
      // console.log('🚀 ~ file: videoThunk.js:31 ~ res:', res);
      const response = await API.get(url);
      console.log('===================getAllVideoList=================');
      console.log(response.data.data);
      dispatch(setVideoList(response.data.data));
      return response;
    } catch (err) {
      dispatch(setVideoLoading(false));
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getSudentVideoActivity = createAsyncThunk(
  'getSudentVideoActivity',
  async (_request, { dispatch }) => {
    let url = `api/v1/student-activity/video-watch/${_request.userId}`;
    try {
      const response = await API.get(url);
      dispatch(setVideoActivityList(response.data.data));
      return response;
    } catch (err) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const addFollowing = createAsyncThunk(
  'addFollowing',
  async (request, { dispatch }) => {
    let url = `api/v1/users/${request.studentId}/followings`;
    try {
      let _request = {
        followeeId: request.followeeId,
      };
      const response = await API.post(url, _request);
      NotificationManager.success(response.data.message, 'Success');
      return true;
    } catch (err) {
      dispatch(setFollowingLoading(false));
      NotificationManager.error(err.response.data.message, 'Error', 3000);
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const createStudentVideoActivity = createAsyncThunk(
  'createActivity',
  async (request, { dispatch }) => {
    console.log("=============Request to createStudentVideoActivity==========", request);
    let url = `api/v1/student-activity/video-watch/${request.userId}`;
    try {
      const { isEmpty = false, ...rest } = request;
      let _request = {
        ...rest,
      };
      const response = await API.post(url, _request);
      console.log("=========createStudentVideoActivity===========", response);
      if (isEmpty) {
        dispatch(removeVideoActivityList());
      }
      dispatch(updateVideoActivityList(response.data.data));
      return true;
    } catch (err) {
      return err;
    }
  },
);
export const createStudentVideoActivityWithEmpty = createAsyncThunk(
  'createActivity',
  async (request, { dispatch }) => {
    let url = `api/v1/student-activity/video-watch/${request.userId}`;
    try {
      let _request = {
        ...request,
      };
      await API.post(url, _request);
      dispatch(removeVideoActivityList());
      return true;
    } catch (err) {
      return err;
    }
  },
);

export const updateStudentVideoActivity = createAsyncThunk(
  'updateActivity',
  async (request, { dispatch }) => {
    console.log("=============Request to updateStudentVideoActivity==========", request);
    const { id, isEmpty = false, assignmentId, ...rest } = request;
    let url = `api/v1/student-activity/video-watch/${id}`;
    try {
      let _request = {
        ...rest,
        assignmentId,
      };
      if (assignmentId) {
        url += `?assignmentId=${assignmentId}`;
      }
      const response = await API.patch(url, _request);
      console.log("=========updateStudentVideoActivity===========", response);
      if (isEmpty) {
        dispatch(removeVideoActivityList());
      } else {
        dispatch(updateVideoActivityList(response.data.data));
      }
      return true;
    } catch (err) {
      return err;
    }
  },
);

export const getStudentVideoActivityReq = createAsyncThunk(
  'getStudentVideoActivityReq',
  async (_request, { dispatch, rejectWithValue }) => {
    console.log("=============Request to getStudentVideoActivityReq==========", _request);
    let url = `api/v1/student-activity/video-watch/${_request.userId}`;
    try {
      const response = await API.get(url);
      console.log("=========getStudentVideoActivityReq response===========", response);
      dispatch(setVideoActivityList(response.data.data));
      return response.data.data;
    } catch (err) {
      NotificationManager.error(err.response.data.message, 'Error', 3000);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllVideoListReq = createAsyncThunk(
  'getAllVideoListReq',
  async (_request, { dispatch, rejectWithValue }) => {
    console.log("=============Request to getAllVideoListReq==========", _request);
    const size = _request?.pageSize || 1;
    const userId = _request?.userId || 1;
    const gradeId = _request?.gradeId || '';
    const topic = _request?.topic;
    let url = `api/v1/assignment/video/${userId}?limit=${size}&userId=${userId}`;
    try {
      if (topic) {
        url += `&topic=${topic}`;
      }
      if (gradeId) {
        url += `&grade=${gradeId}`;
      }
      dispatch(setVideoLoading(true));
      const response = await API.get(url);
      console.log("=========getAllVideoListReq response===========", response);
      dispatch(setVideoList(response.data.data));
      dispatch(setVideoLoading(false));
      return response.data.data;
    } catch (err) {
      // dispatch(setVideoLoading(false));
      NotificationManager.error(err.response.data.message, 'Error', 3000);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getRelatedSkillVideos = createAsyncThunk(
  'relatedSkillVideos',
  async (request, { dispatch }) => {
    const { id, skill, ...rest } = request;
    let url = `api/v1/videos/skill/${id}?skill=${skill}`;
    try {
      let _request = {
        ...rest,
      };
      const response = await API.get(url, _request);
      return response.data.data;
    } catch (err) {
      NotificationManager.error(err.response.data.message, 'Error', 3000);
      return err;
    }
  },
);

import { Avatar, Box, Card, Stack } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { Fragment, forwardRef, useState } from 'react';
import VideoActions from './VideoActions';
import crossAvatar from '../../../../../media/add-circle.png';
import userAvatar from '../../../../../media/img-1.png';
import TickAvatar from '../../../../../media/tick-square.png';
import { secondsToMinutes } from 'utils/index';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import TemplateQuizDialog from 'dialog/TemplateQuiz';
import { getQuizListApi } from 'requests/Template';

function LessonComment(
  {
    video,
    fetchData,
    totalVideoDuration,
    videoActivity,
    moreExample,
    moveToNextSkill,
    noMore,
    paused,
    alreadyCompleted,
  },
  ref,
) {
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.userReducer.userData);
  const dispatch = useAppDispatch();
  const [failedQuiz, setFailedQuiz] = useState(false);


  function removeSeqN(text) {
    const arrs = text.split('_');
    return arrs[arrs.length - 1];
  }
  return (
    <Box
      sx={{
        maxHeight: '90vh',
        overflowY: 'auto',
        m: { xs: 1, sm: 2, md: 2, lg: 1 },
      }}
    >
      {video && (
        <Card
          sx={{ boxShadow: 'none', borderRadius: '10px', marginBottom: '10px' }}
        >
          <CardHeader
            avatar={
              <Avatar
                alt={video?.user?.name || 'A'}
                sx={{ width: 50, height: 50 }}
                src={userAvatar}
              />
            }
            title={
              <Typography
                variant="h6"
                style={{
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#16191C',
                }}
              >
                {video?.user?.name}
              </Typography>
            }
            subheader={
              <Typography
                variant="h6"
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#808080',
                }}
              >
                {video?.user?.type}
              </Typography>
            }
          />
          <CardContent style={{ marginBottom: '10px' }}>
            <Typography
              variant="body2"
              alignItems="center"
              display={'flex'}
              color="text.secondary"
              sx={{ fontSize: '18px', color: '#16191C' }}
            >
              <strong fontWeight="700">
                {video?.topic?.[0]?.topic?.name} :{' '}
              </strong>
              <Typography
                sx={{ fontSize: '16px', color: '#16191C' }}
                paddingLeft={'4px'}
              >
                {removeSeqN(video?.title)}
              </Typography>
            </Typography>
          </CardContent>
        </Card>
      )}
      <VideoActions />
      {parseInt(videoActivity?.timeWatched) > 0 && (
        <Fragment>
          {videoActivity && totalVideoDuration && (
            <Box
              backgroundColor={'#F8F8F8'}
              padding={'30px 32px'}
              marginBottom={'10px'}
              sx={{ boxShadow: 'none', borderRadius: '10px' }}
            >
              <Box
                alignItems="center"
                display={'flex'}
                justifyContent="space-between"
                sx={{ whiteSpace: 'nowrap' }}
              >
                <Typography
                  variant="body2"
                  alignItems="center"
                  display={'flex'}
                  color="text.secondary"
                  sx={{ fontSize: '18px', color: '#16191C' }}
                >
                  <strong fontWeight="700">Total Length: </strong>
                  {totalVideoDuration
                    ? secondsToMinutes(totalVideoDuration)
                    : '0 min'}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                display={'flex'}
                justifyContent="center"
                paddingTop={'20px'}
              >
                <Typography paddingRight={'10px'}>
                  <Avatar
                    alt={video?.user?.name || 'A'}
                    sx={{ width: 50, height: 50 }}
                    className="img-cross"
                    src={
                      parseInt(videoActivity?.timeWatched) ===
                        parseInt(totalVideoDuration)
                        ? TickAvatar
                        : crossAvatar
                    }
                  />
                </Typography>
                <Typography alignItems="center" display={'flex'}>
                  <strong
                    fontWeight="700"
                    fontSize="24px"
                    color="#000"
                    display={'flex'}
                  >
                    Total Time Watch :
                  </strong>
                  <Typography
                    fontWeight="700"
                    color={
                      parseInt(videoActivity?.timeWatched) ===
                        parseInt(totalVideoDuration)
                        ? '#C73E9D'
                        : '#3EC7AC'
                    }
                    paddingLeft={'4px'}
                  >
                    {videoActivity
                      ? secondsToMinutes(videoActivity?.timeWatched)
                      : '0 sec'}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          )}
          {videoActivity &&
            totalVideoDuration &&
            !videoActivity?.completed &&
            parseInt(videoActivity?.timeWatched) !==
            parseInt(totalVideoDuration) &&
            !noMore &&
            paused && (
              <Box
                backgroundColor={'#F8F8F8'}
                padding={'1rem'}
                sx={{ boxShadow: 'none', borderRadius: '10px' }}
                height={'100%'}
                textAlign={'center'}
              >
                <Typography
                  fontSize={'24px'}
                  lineHeight={'1.2'}
                  fontWeight={'600'}
                  color={'#000'}
                  marginBottom={'1rem'}
                >
                  Looks like you did NOT finish watching the video lesson?
                </Typography>
                <Typography onClick={() => ref.current.play()}>
                  <button className="btn-primary">Continue Lesson</button>
                </Typography>
              </Box>
            )}
          {totalVideoDuration &&
            videoActivity &&
            videoActivity?.completed &&
            parseInt(videoActivity?.timeWatched) ===
            parseInt(totalVideoDuration) &&
            !noMore && (
              <Box
                backgroundColor={'#F8F8F8'}
                padding={'1rem'}
                sx={{ boxShadow: 'none', borderRadius: '10px' }}
                height={'100%'}
                textAlign={'center'}
              >
                <Typography
                  fontSize={'24px'}
                  lineHeight={'1.2'}
                  fontWeight={'600'}
                  color={'#000'}
                  marginBottom={'1rem'}
                >
                  {
                    alreadyCompleted ? (
                      <>
                        You have already watched this video and earned $G3MS.
                      </>
                    ) : (
                      <>
                        Great job completing this video lesson.
                        <span style={{ color: '#C73E9D' }}>
                          You have earned {videoActivity.tokensEarned} $G3MS!
                        </span>{' '}
                      </>)
                  }

                </Typography>
                <Typography
                  fontSize={'24px'}
                  lineHeight={'1.2'}
                  fontWeight={'600'}
                  color={'#000'}
                  marginBottom={'20px'}
                >
                  Time to put your learning to the test and{' '}
                  <span style={{ color: '#C73E9D' }}>
                    earn more $G3MS tokens!
                  </span>
                </Typography>
                <Typography
                  fontSize={'24px'}
                  lineHeight={'1.2'}
                  fontWeight={'600'}
                  color={'#000'}
                  marginBottom={'20px'}
                >
                  Ready for a quick quiz on this skill?
                </Typography>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'space-between'}
                  gap={'10px'}
                >
                  <button
                    style={{ width: '50%' }}
                    className="btn-outline"
                    onClick={() => {
                      moreExample();
                    }}
                  >
                    I need more examples
                  </button>
                  <button
                    onClick={() => {
                      // updateAssignmentDone({
                      //   assignmentId: video?.Assignments?.[0]?.assignmentId,
                      // }).catch(console.error);
                      getQuizListApi({
                        skillId: video?.skill?.[0]?.skillId,
                        userId: userData?.id,
                        classroomId:
                          video?.Assignments?.[0]?.assignment?.classroomId,
                      })
                        .then((res) => {
                          if (!res.data) {
                            setFailedQuiz(true);
                          } else {
                            moveToNextSkill(true);
                            navigate(
                              `/app/student/daily-quiz?skill=${video?.skill?.[0]?.skillId}`,
                            );
                          }
                        })
                        .catch(console.error);
                    }}
                    className="btn-secondary"
                    style={{ width: '50%' }}
                  >
                    Let's Do it
                  </button>
                </Stack>
              </Box>
            )}
          <TemplateQuizDialog
            open={failedQuiz}
            onClose={() => {
              moveToNextSkill(true);
              setFailedQuiz(false);
            }}
          />
          {noMore && (
            <Box
              backgroundColor={'#F8F8F8'}
              padding={'88px 32px 60px'}
              sx={{ boxShadow: 'none', borderRadius: '10px' }}
              height={'100%'}
              textAlign={'center'}
            >
              <Typography
                fontSize={'24px'}
                lineHeight={'1.2'}
                fontWeight={'600'}
                color={'#000'}
                marginBottom={'20px'}
              >
                Oops! Looks we have no more videos to teach you this skill.
              </Typography>
              <Typography
                fontSize={'24px'}
                lineHeight={'1.2'}
                fontWeight={'600'}
                color={'#000'}
                marginBottom={'20px'}
              >
                Time to take the quiz or skip this skill and move to the next
                skill?
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                gap={'10px'}
              >
                <button
                  style={{ width: '50%' }}
                  className="btn-outline"
                  onClick={() => {
                    moveToNextSkill();
                  }}
                >
                  Move to next skill
                </button>
                <button
                  onClick={() => {
                    getQuizListApi({
                      skillId: video?.skill?.[0]?.skillId,
                      userId: userData?.id,
                      classroomId:
                        video?.Assignments?.[0]?.assignment?.classroomId,
                    })
                      .then((res) => {
                        if (!res.data) {
                          setFailedQuiz(true);
                        } else {
                          navigate(
                            `/app/student/daily-quiz?skill=${video?.skill?.[0]?.skillId}`,
                          );
                        }
                      })
                      .catch(console.error);
                  }}
                  className="btn-secondary"
                  style={{ width: '50%' }}
                >
                  Take me to the quiz
                </button>
              </Stack>
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
}

export default forwardRef(LessonComment);
